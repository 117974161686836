<template>
    <div>
        <el-button type="primary" @click="derive()" v-if="deriveButton" class="derive_button">导出</el-button>
        <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="flawApplicationForm"
            label-position="top"
            :disabled="flawApplicationDisabled"
        >
            <div class="ulbox">
                <el-card class="customcard">
                    <!-- <div slot="header" class="clearfix">
                        <span>{{ '隐患信息' }}</span>
                    </div> -->
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item :label="'变电站或线路名称'" prop="substationOrLineName">
                                <el-cascader
                                    :key="isResouceShow"
                                    v-model="dataForm.substationOrLineName"
                                    :options="substationOrLineNameOption_"
                                    :props="{
                                        value: 'id',
                                        label: 'name',
                                        key: 'id',
                                        children: 'children',
                                    }"
                                    style="width: 100%"
                                    @change="substationOrLineChange"
                                    placeholder="请输入变电站或线路名称"
                                ></el-cascader>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'设备名称'" prop="facilityName">
                                <el-cascader
                                    :key="isResouceShow_2"
                                    v-model="dataForm.facilityName"
                                    :options="facilityNameOption"
                                    style="width: 100%"
                                    :props="{
                                        value: 'id',
                                        label: 'name',
                                        key: 'id',
                                        children: 'children',
                                    }"
                                    @change="facilityNameOptionChange"
                                    placeholder="请输入变电站或线路名称"
                                ></el-cascader>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'设备电压等级'" prop="facilityVoltage">
                                <el-select v-model="dataForm.facilityVoltage" placeholder="请输入设备电压等级">
                                    <el-option
                                        v-for="item in this.facilityVoltageOption"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'隐患等级'" prop="flawEstate">
                                <el-select v-model="dataForm.flawEstate" placeholder="请选择隐患等级">
                                    <el-option
                                        v-for="item in this.flawLevelOption"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item :label="'隐患发现时间'" prop="discoveryTime">
                                <el-date-picker
                                    v-model="dataForm.discoveryTime"
                                    type="datetime"
                                    value-format="yyyy-MM-dd hh-mm"
                                    format="yyyy-MM-dd hh-mm"
                                    placeholder="选择日期发现时间"
                                    style="width: 100%"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'隐患发现人'" prop="discoveryUser">
                                <!-- <el-select v-model="dataForm.discoveryUser" placeholder="请输入发现人">
                                    <el-option
                                        v-for="item in this.userList"
                                        :key="item.userId"
                                        :label="item.realName"
                                        :value="item.userId"
                                    >
                                    </el-option>
                                </el-select> -->
                                <el-input v-model="dataForm.discoveryUser" placeholder="请输入发现人"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'发现单位'" prop="discoveryUnits">
                                <!-- <el-input v-model="dataForm.discoveryUnits" placeholder="请输入发现单位"></el-input> -->
                                <el-select v-model="dataForm.discoveryUnits" placeholder="请输入通知单位" clearable>
                                    <el-option
                                        v-for="item in notifyUnitsOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.name"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'问题分类'" prop="questionClassify">
                                <el-select v-model="dataForm.questionClassify" placeholder="请输入问题分类">
                                    <el-option
                                        v-for="item in this.questionClassifyOption"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item :label="'当前隐患是否造成停电'" prop="isPowerCut">
                                <el-select v-model="dataForm.isPowerCut" @change="isPowerCutChange">
                                    <el-option
                                        v-for="item in isTrue"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'停电范围'" prop="PowerRange">
                                <el-input
                                    v-model="dataForm.PowerRange"
                                    placeholder="请输入"
                                    :disabled="dataForm.isPowerCut != 'true'"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item :label="'处置隐患是否需要停电'" prop="isNeedPower">
                                <el-select v-model="dataForm.isNeedPower" placeholder="请选择处置隐患是否需要停电">
                                    <el-option
                                        v-for="item in this.isTrue"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'计划停电开始-计划停电结束时间'" prop="powerStartAndEndTime">
                                <el-date-picker
                                    v-model="dataForm.powerStartAndEndTime"
                                    type="datetimerange"
                                    range-separator="至"
                                    align="right"
                                    unlink-panels
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd hh-mm"
                                    format="yyyy-MM-dd hh-mm"
                                    class="range_time"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'处理时长'" prop="handleTime">
                                <!-- <el-input v-model="dataForm.handleTime" placeholder="请输入处理时长" type="textarea">
                                </el-input> -->
                                <el-input placeholder="请输入内容" v-model="dataForm.handleTime">
                                    <el-select
                                        slot="append"
                                        placeholder="请选择"
                                        v-model="dataForm.needPower"
                                        class="handle_units"
                                    >
                                        <!-- <el-option label="餐厅名" value="1"></el-option>
                                        <el-option label="订单号" value="2"></el-option>
                                        <el-option label="用户电话" value="3"></el-option> -->
                                        <el-option
                                            v-for="item in handleUnit"
                                            :key="item.name"
                                            :label="item.name"
                                            :value="item.name"
                                        ></el-option>
                                    </el-select>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'预计处理所需要的材料及实验设备'" prop="handleMaterials">
                                <el-input
                                    v-model="dataForm.handleMaterials"
                                    placeholder="请输入所需要的材料及实验设备"
                                    type="textarea"
                                    :rows="2"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="问题描述" prop="problem">
                                <el-input
                                    v-model="dataForm.problem"
                                    placeholder="请输入问题描述"
                                    type="textarea"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="处理建议" prop="handleSuggest">
                                <el-input
                                    v-model="dataForm.handleSuggest"
                                    placeholder="请输入处理建议"
                                    type="textarea"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <div style="margin-top: 20px">
                        <div slot="header" class="clearfix" style="margin-bottom: 20px">
                            <span>设备信息</span>
                        </div>
                        <el-row :gutter="24">
                            <el-col :span="8">
                                <el-form-item label="设备生产厂家" prop="facilityMessage">
                                    <el-input
                                        v-model="dataForm.facilityMessage"
                                        placeholder="请输入设备生产厂家"
                                    ></el-input> </el-form-item
                            ></el-col>
                            <el-col :span="8">
                                <el-form-item label="设备编号" prop="facilityCode">
                                    <el-input
                                        v-model="dataForm.facilityCode"
                                        placeholder="请输入设备编号"
                                    ></el-input> </el-form-item
                            ></el-col>
                            <el-col :span="8">
                                <el-form-item label="设备投产时间" prop="facilityOperationTime">
                                    <el-date-picker
                                        type="date"
                                        value-format="yyyy-MM-dd"
                                        format="yyyy-MM-dd"
                                        v-model="dataForm.facilityOperationTime"
                                        placeholder="请输入设备投产时间"
                                        style="width: 100%"
                                    >
                                    </el-date-picker> </el-form-item
                            ></el-col>
                        </el-row>
                        <el-row>
                            <el-form-item label="消缺前照片" prop="imgeUrl">
                                <ex-uploader v-model="dataForm.imgeUrl"> </ex-uploader>
                            </el-form-item>
                        </el-row>
                    </div>
                </el-card>
            </div>
        </el-form>
        <br />
        <!-- <div class="submit-btn-group" v-if="flawApplicationButton">
            <el-button @click="close()">关闭</el-button>
            <el-button @click="createApplication()" type="primary">提交</el-button>
        </div> -->
    </div>
</template>
<script>
import {EQUIPMENT_MAIN_CATEGORY} from '@/utils/constants.js';
import axios from '@/client/axios.js';
const isTrue = [
    {name: '是', value: 'true'},
    {name: '否', value: 'false'},
];
const handleUnit = [
    {name: '天', value: '天'},
    {name: '小时', value: '小时'},
    {name: '分', value: '分'},
];
const equipmentList = [
    {
        cname: '主变电站',
        name: EQUIPMENT_MAIN_CATEGORY.MAIN_SUBSTATION,
        uploadUrl: `/business/mainSubstations/`,
    },
    {
        cname: '箱式变电站',
        name: EQUIPMENT_MAIN_CATEGORY.SUBSTATION,
        uploadUrl: `/business/substations/`,
    },
    {
        cname: '杆塔',
        name: EQUIPMENT_MAIN_CATEGORY.TOWER_POLE,
        uploadUrl: `/business/towerPoles/`,
    },
    {
        cname: '杆上开关',
        name: EQUIPMENT_MAIN_CATEGORY.TOWER_POLE_SWITCH,
        uploadUrl: `/business/towerPoleSwitches/`,
    },
    {
        cname: '户外开关站',
        name: EQUIPMENT_MAIN_CATEGORY.RING_MAIN_UNIT,
        uploadUrl: `/business/ringMainUnits/`,
    },
    {
        cname: '线缆',
        name: EQUIPMENT_MAIN_CATEGORY.WIRE,
        uploadUrl: `/business/wires/`,
    },
];
export default {
    name: 'flawApplication',
    components: {},
    data() {
        let valiNumberPass = (rule, value, callback) => {
            //包含小数的数字
            let reg = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g;
            if (value === '') {
                callback(new Error('请输入内容'));
            } else if (!reg.test(value)) {
                callback(new Error('请输入数字'));
            } else {
                callback();
            }
        };
        let valiPowerRange = (rule, value, callback) => {
            console.log(this.dataForm.isPowerCut);

            if (this.dataForm.isPowerCut === 'true' && value === '') {
                callback(new Error('请输入内容'));
            } else {
                callback();
            }
        };
        return {
            // tableVisible: true,
            flawApplicationDisabled: false,
            flawApplicationButton: true,
            roleList: [],

            dataForm: {
                substationOrLineName: '',
                facilityName: '',
                facilityVoltage: '',
                flawEstate: '',
                discoveryTime: '',
                discoveryUser: '',
                discoveryUnits: '',
                questionClassify: '',
                isPowerCut: '',
                PowerRange: '',
                isNeedPower: '',
                powerStartAndEnd: '',
                handleTime: '',
                needPower: '小时',
                handleMaterials: '',
                problem: '',
                handleSuggest: '',
                facilityMessage: '',
                facilityCode: '',
                facilityOperationTime: '',
                imgeUrl: [],
                facilityNameOption: [],
            },
            valiNumberPass,
            discoveryUserList: [],
            dataRule: {
                substationOrLineName: [{required: true, message: '请输入', trigger: 'blur'}],
                facilityName: [{required: true, message: '请输入', trigger: 'blur'}],
                facilityVoltage: [{required: true, message: '请输入', trigger: 'blur'}],
                flawEstate: [{required: true, message: '请输入', trigger: 'blur'}],
                discoveryTime: [{required: true, message: '请输入', trigger: 'blur'}],
                discoveryUser: [{required: true, message: '请输入', trigger: 'blur'}],
                discoveryUnits: [{required: true, message: '请输入', trigger: 'blur'}],
                questionClassify: [{required: true, message: '请输入', trigger: 'blur'}],
                isPowerCut: [{required: true, message: '请输入', trigger: 'blur'}],
                PowerRange: [{required: false, validator: valiPowerRange, message: '请输入', trigger: 'blur'}],
                isNeedPower: [{required: true, message: '请输入', trigger: 'blur'}],
                powerStartAndEnd: [{required: true, message: '请输入', trigger: 'blur'}],
                handleTime: [{required: true, validator: valiNumberPass, trigger: 'blur', message: '请输入'}],
                needPower: [{required: true, message: '请输入', trigger: 'blur'}],
                handleMaterials: [{required: true, message: '请输入', trigger: 'blur'}],
                problem: [{required: true, message: '请输入', trigger: 'blur'}],
                handleSuggest: [{required: true, message: '请输入', trigger: 'blur'}],
                facilityMessage: [{required: false, message: '请输入', trigger: 'blur'}],
                facilityCode: [{required: false, message: '请输入', trigger: 'blur'}],
                facilityOperationTime: [{required: false, message: '请输入', trigger: 'blur'}],
                imgeUrl: [{required: false, message: '请输入', trigger: 'blur'}],
            },
            uploadLoading: null,
            flawLevel: [],
            deriveButton: false,
            isTrue,
            handleUnit,
            facilityNameOption: [],
            substationOrLineNameOption_: [],
            facilityDetails: {}, //设备信息
            equipmentList,
            facilityNameinit: false,
            isResouceShow: 0,
            isResouceShow_2: 0,
        };
    },
    props: [
        'facilityVoltageOption',
        'questionClassifyOption',
        'userList',
        'flawLevelOption',
        'substationOrLineNameOption',
        'notifyUnitsOptions',
    ],
    watch: {
        substationOrLineNameOption: function () {
            let obj = JSON.stringify(this.substationOrLineNameOption);
            this.substationOrLineNameOption_ = JSON.parse(obj); //深拷贝
            this.substationOrLineNameOption_ = this.substationOrLineNameOption_.map((item) => {
                if (item.children != undefined) {
                    item.children = item.children.filter((e) => {
                        if (e.children != undefined) {
                            e.children = null;
                            return true;
                        }
                        return false;
                    });
                }
                return item;
            });
            ++this.isResouceShow;
        },
    },
    methods: {
        async init(dataForm) {
            this.facilityNameinit = false;
            if (dataForm != null) {
                this.dataForm = dataForm;
                this.facilityNameinit = true;
                await this.substationOrLineChange(dataForm.substationOrLineName);
                this.dataForm.facilityName = dataForm.facilityNameOption;
                this.flawApplicationDisabled = true;
            }
            this.flawApplicationButton = false;
            this.closeDeriveButton();
        },
        createForm() {
            this.substationOrLineChange(this.dataForm.substationOrLineName);
            let func = this.handleTreeData(this.dataForm.facilityName.length + 1, this.dataForm.facilityName);
            let data = func(this.facilityNameOption);
            if (data.length > 0) this.dataForm.facilityName = data[0].name;
            return this.dataForm;
        },
        activateForm() {
            // debugger;
            this.clearform();
            this.dataForm.discoveryUser = this.$store.state.userInfo.realName; // 发现人
            // this.dataForm.discoveryUnits = this.$store.state.userInfo.orgList[0].name;
            this.flawApplicationDisabled = false;
            this.facilityNameinit;
        },
        activateButton() {
            this.flawApplicationButton = true;
        },
        close() {
            this.clearform();
            this.flawApplicationDisabled = false;
            // this.tableVisible = false;
            this.$emit('refreshDataList');
        },
        closeForm() {
            this.flawApplicationDisabled = true;
            this.$refs.flawApplicationForm.clearValidate();
        },
        async createApplication() {
            this.$confirm(`确定提交`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$refs.flawApplicationForm.validate(async (vaild) => {
                    if (vaild) {
                        this.dataForm.username = this.$store.state.userInfo.username;
                        this.dataForm.notifyUnits =
                            this.$store.state.userInfo.orgList.length > 0
                                ? this.$store.state.userInfo.orgList[0].name
                                : '';
                        // debugger;
                        let nextRoles = [];
                        let nextCode = ['HIDDEN_MANAGE'];
                        await this.$client.getRolesBycodes(nextCode).then((res) => {
                            nextRoles = res.data.map((item) => item.roleId);
                        });
                        let facilityDetails = this.facilityDetails;
                        let isUpdatefacilityDetails = false;
                        let facilityId = '';
                        if (
                            this.facilityDetails.manufacturer !== this.dataForm.facilityMessage ||
                            this.facilityDetails.number !== this.dataForm.facilityCode ||
                            this.facilityDetails.commissionDate !== this.dataForm.facilityOperationTime
                        ) {
                            //更新设备
                            isUpdatefacilityDetails = true;
                            facilityDetails.manufacturer = this.dataForm.facilityMessage;
                            facilityDetails.number = this.dataForm.facilityCode;
                            facilityDetails.commissionDate = this.dataForm.facilityOperationTime;
                            facilityId = this.dataForm.facilityName;
                        }
                        let func = this.handleTreeData(
                            this.dataForm.facilityName.length + 1,
                            this.dataForm.facilityName
                        );
                        let data = func(this.facilityNameOption);
                        this.dataForm.facilityNameOption = this.dataForm.facilityName;
                        ++this.isResouceShow_2;
                        // debugger;
                        if (data.length > 0) this.dataForm.facilityName = data[0].name;
                        const applicationForm = {
                            definitionCode: 'elec_dispatcn_hidden',
                            nodeCode: 'elec_dispatcn_hidden_add_apply',
                            operationType: 'saveAndNext',
                            rejectNodeCode: '',
                            data: {flawApplication: this.dataForm},
                            extraProp: {flawEstate: this.dataForm.flawEstate},
                            // nextUsers: [this.$store.state.userInfo.username],
                            // nextRoles: nextRoles,
                        };
                        await this.$client.workflowInstanceProcess(applicationForm).then(async (res) => {
                            //发起
                            if (isUpdatefacilityDetails) this.updatefacility(facilityId, facilityDetails); //更新设备
                            applicationForm.instanceId = res.data.instance.id;
                            await this.$client.workflowInstanceProcess(applicationForm).then(async (res2) => {
                                //执行
                                await this.$emit('refreshDataList');
                            });
                        });
                        this.closeForm();
                        return true;
                    } else {
                        return false;
                    }
                });
            });
        },
        handleTreeData(cout, indexList) {
            let num = 0;
            return function handleData2(list) {
                //对一个list递归cout次
                if (num < cout) {
                    let parent = list.filter((item) => item.name === indexList[num]);
                    num++;
                    // console.log(list,indexList[num],parent);
                    if (parent.length > 0 && parent[0].children != null) {
                        let children = handleData2(parent[0].children);

                        return children;
                    }
                    return list;
                }

                return list;
            };
        },
        async substationOrLineChange(indexList) {
            if (indexList == null) return;
            if (!this.facilityNameinit) {
                this.dataForm.facilityName = '';
            }
            let func = this.handleTreeData(indexList.length + 1, indexList);
            let data = await func(this.substationOrLineNameOption);
            this.facilityNameOption = data != null ? data : null;
            ++this.isResouceShow_2;
        },
        facilityNameOptionChange(indexList) {
            // debugger;
            if (indexList == null) return;
            let func = this.handleTreeData(indexList.length + 1, indexList);
            let obj = func(this.facilityNameOption); //搜索树
            if (obj.length > 0) {
                let code = obj[0].name.match('[(（].*[)）]')[0];
                code = code.substring(1, code.length - 1);
                if (code != null) {
                    this.$client.equipmentInfo({number: code}).then((res) => {
                        this.facilityDetails = res.data.data;
                        this.dataForm.facilityMessage = res.data.data.manufacturer;
                        this.dataForm.facilityCode = res.data.data.number;
                        this.dataForm.facilityOperationTime = res.data.data.commissionDate;
                    });
                }
            }
        },
        updatefacility(indexList, data) {
            // ;
            if (indexList == null) return;
            let func = this.handleTreeData(indexList.length + 1, indexList);
            let obj = func(this.facilityNameOption); //搜索树
            if (obj.length > 0) {
                let code = obj[0].name.match('[(（].*[)）]')[0];
                code = code.substring(1, code.length - 1);
                if (code != null) {
                    this.$client.equipmentInfo({number: code}).then((res) => {
                        let type = this.equipmentList.filter((item) => item.name === res.data.type); //选出url
                        if (type.length > 0) {
                            axios.put(type[0].uploadUrl + res.data.data.id, data).then((res) => {
                                console.log('设备更新成功');
                            });
                        }
                    });
                }
            }
        },
        derive() {
            fileDownload('/business/ElecDispatchFlawManage/workTaskInformDownload', {
                method: 'POST',
                data: this.dataForm,
            });
        },
        activateDeriveButton() {
            this.deriveButton = true;
        },
        closeDeriveButton() {
            this.deriveButton = false;
        },
        isPowerCutChange(data) {
            this.dataForm.PowerRange = '';
        },
        handleTreeData(cout, indexList) {
            let num = 0;
            return function handleData2(list) {
                //对一个list递归cout次
                if (num < cout) {
                    let parent = list.filter((item) => item.id === indexList[num]);
                    num++;
                    // console.log(list,indexList[num],parent);
                    if (parent.length > 0 && parent[0].children != null) {
                        let children = handleData2(parent[0].children);
                        return children;
                    }
                    if (parent.length === 1) return parent;
                    return list;
                }
                return list;
            };
        },
        clearform() {
            this.dataForm = {
                substationOrLineName: '',
                facilityName: '',
                facilityVoltage: '',
                flawEstate: '',
                discoveryTime: '',
                discoveryUser: '',
                discoveryUnits: '',
                questionClassify: '',
                isPowerCut: '',
                PowerRange: '',
                isNeedPower: '',
                powerStartAndEnd: '',
                handleTime: '',
                needPower: '小时',
                handleMaterials: '',
                problem: '',
                handleSuggest: '',
                facilityMessage: '',
                facilityCode: '',
                facilityOperationTime: '',
                imgeUrl: [],
                facilityNameOption: [],
            };
        },
    },
};
</script>
<style lang="less">
._handleUnit {
    width: 8%;
    margin-top: 25px;
    // padding: 0px;
    position: relative;
    right: 45px;
}

.el-form-item {
    .el-input__inner {
        width: 100%;
    }
    // margin: 0px;
    .el-range-editor.is-disabled {
        background-color: #0b1020;
        border-color: #0b1020;
        color: #eeeff1;
        .el-range-input {
            background-color: #0b1020;
            color: #c0c4cc;
        }
        width: 100%;
        height: 80%;
    }
    .handle_unit {
        // background-color: #0b1020;
        // border-color: #0b1020;
        // color: #eeeff1;
        .el-input__inner {
            background-color: #161d30;
            border-color: #0b1020;
            // color: #eeeff1;
            border-top: 0;
            width: 100%;
            border-bottom: 0;
        }
    }
    .el-input-group__append,
    .el-input-group__prepend {
        background-color: #161d30;
        padding: 0 !important;
        margin: 0 !important;
        width: 80px;
        height: 26px;
    }

    .el-input-group__append .el-select,
    .el-input-group__prepend .el-select {
        margin: 0 !important;
        height: 26px;
        color: #c0c4cc;
    }

    .is-disabled {
        .el-input-group__append,
        .el-input-group__prepend {
            border: none !important;
            background-color: #0b1020;
        }
    }
}
</style>
