<template>
    <el-card class="customcard card_table">
        <!-- <div slot="header" class="clearfix">
            <span>{{ '竣工验收报告' }}</span>
        </div> -->
        <el-button type="primary" @click="derive()" v-if="deriveButton" class="derive_button">导出</el-button>
        <el-form
            :model="dataForm"
            ref="completionAccetanceForm"
            :disabled="completionAcceptanceDisabled"
            :rules="dataRule"
            label-position="top"
        >
            <el-row>
                <el-col>
                    <table border="1" width="100%">
                        <tr>
                            <td class="table_td"><strong>*</strong>工程名称</td>
                            <td>
                                <el-form-item prop="projectName"
                                    ><el-input v-model="dataForm.projectName" placeholder="请输入工程名称"></el-input>
                                </el-form-item>
                            </td>
                            <td class="table_td"><strong>*</strong>验收时间</td>
                            <td>
                                <el-form-item prop="checkTime">
                                    <el-date-picker
                                        type="datetime"
                                        v-model="dataForm.checkTime"
                                        placeholder="选择日期时间"
                                        value-format="yyyy-MM-dd hh-mm"
                                        format="yyyy-MM-dd hh-mm"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <td class="table_td"><strong>*</strong>施工单位</td>
                            <td>
                                <el-form-item prop="buildUnit">
                                    <el-input v-model="dataForm.buildUnit" placeholder="请输入施工单位"></el-input>
                                </el-form-item>
                            </td>
                            <td class="table_td"><strong>*</strong>施工负责人</td>
                            <td>
                                <el-form-item prop="buildPrinciplUser">
                                    <el-input
                                        v-model="dataForm.buildPrinciplUser"
                                        placeholder="请输入施工负责人"
                                    ></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <td class="table_td"><strong>*</strong>开工时间</td>
                            <td>
                                <el-form-item prop="startWorkTime">
                                    <el-date-picker
                                        type="datetime"
                                        v-model="dataForm.startWorkTime"
                                        placeholder="请输入开工时间"
                                        value-format="yyyy-MM-dd hh-mm"
                                        format="yyyy-MM-dd hh-mm"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                            <td class="table_td"><strong>*</strong>竣工时间</td>
                            <td>
                                <el-form-item prop="comletionTime">
                                    <el-date-picker
                                        type="datetime"
                                        v-model="dataForm.comletionTime"
                                        placeholder="选择日期时间"
                                        value-format="yyyy-MM-dd hh-mm"
                                        format="yyyy-MM-dd hh-mm"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <el-table border :data="dataForm.buildList">
                                    <el-table-column type="index" prop="index" label="序号" width="100">
                                    </el-table-column>
                                    <el-table-column prop="projectResult" label="施工单位与部门">
                                        <template slot-scope="scope">
                                            <el-input
                                                v-model="scope.row.units"
                                                placeholder="请输施工单位与部门"
                                            ></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="unit" label="施工人员">
                                        <template slot-scope="scope">
                                            <el-input
                                                v-model="scope.row.username"
                                                placeholder="请输入施工人员"
                                            ></el-input> </template
                                    ></el-table-column>
                                    <el-table-column width="50" label="操作">
                                        <template slot-scope="scope">
                                            <div style="text-align: center" v-if="completionAcceptanceDisabled">
                                                <i
                                                    class="el-icon-remove"
                                                    title=""
                                                    @click="delTableData(scope.$index)"
                                                ></i>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-button style="width: 100%" @click="addProject" v-if="addButtonShow">添加</el-button>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td class="table_td"><strong>*</strong>参与单位或部门</td>
                            <td colspan="4">
                                <el-form-item prop="participationUnit">
                                    <el-input
                                        v-model="dataForm.participationUnit"
                                        placeholder="请输入参加单位"
                                    ></el-input>
                                </el-form-item>
                            </td>
                        </tr>
                        <tr>
                            <td class="table_td"><strong>*</strong>参与人</td>
                            <td colspan="4">
                                <el-form-item prop="participationUnitUser">
                                    <el-input
                                        v-model="dataForm.participationUnitUser"
                                        placeholder="请输入参与人,多个参与人用，隔开"
                                    ></el-input>
                                </el-form-item>
                            </td>
                        </tr> -->
                        <tr>
                            <th rowspan="5">验收情况</th>
                            <td class="table_td" colspan="1">主要工程量及主要技术参数</td>
                            <td colspan="3">
                                <el-input
                                    v-model="dataForm.PrinciplprojectAndParmas"
                                    placeholder="请输入主要工程"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <ex-uploader v-model="dataForm.checkImage"> </ex-uploader>
                            </td>
                        </tr>
                        <tr>
                            <td class="table_td" colspan="1">验收人员</td>
                            <td colspan="3">
                                <el-input
                                    v-model="dataForm.checkUser"
                                    placeholder="请输入验收人员，多个参与人用，隔开"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="table_td" colspan="1">复查人员</td>
                            <td colspan="2">
                                <el-input
                                    v-model="dataForm.repetitionCheckUser"
                                    placeholder="请输入复查人员"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="table_td" colspan="1">验收评估</td>
                            <td colspan="2">
                                <el-input v-model="dataForm.checkAssess" placeholder="请输入验收评估"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1">备注</td>
                            <td colspan="3">
                                <el-input v-model="dataForm.remark" placeholder="请输入备注"></el-input>
                            </td>
                        </tr>
                    </table>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</template>
<script>
export default {
    name: 'cpmletionReceive',
    components: {},
    data() {
        return {
            completionAcceptanceDisabled: false,
            deriveButton: false,
            addButtonShow: true,
            dataForm: {
                projectName: '',
                checkTime: '',
                buildUnit: '',
                buildPrinciplUser: '',
                startWorkTime: '',
                comletionTime: '',
                participationUnit: '',
                participationUnitUser: '',
                PrinciplprojectAndParmas: '',
                checkImage: [],
                buildList: [],
                checkUser: '',
                repetitionCheckUser: '',
                checkAssess: '',
                remark: '',
            },
            dataRule: {
                projectName: [{required: true, message: '请输入', trigger: 'blur'}],
                checkTime: [{required: true, message: '请输入', trigger: 'blur'}],
                buildUnit: [{required: true, message: '请输入', trigger: 'blur'}],
                buildPrinciplUser: [{required: true, message: '请输入', trigger: 'blur'}],
                startWorkTime: [{required: true, message: '请输入', trigger: 'blur'}],
                comletionTime: [{required: true, message: '请输入', trigger: 'blur'}],
                participationUnit: [{required: true, message: '请输入', trigger: 'blur'}],
                participationUnitUser: [{required: true, message: '请输入', trigger: 'blur'}],
            },
            info: {},
            projectList: [],
        };
    },
    methods: {
        async init(dataForm) {
            if (dataForm != null) {
                this.dataForm = dataForm;
                this.completionAcceptanceDisabled = true;
            }
            this.addButtonShow = true;
            this.closeDeriveButton();
        },
        createForm() {
            return this.dataForm;
        },
        activateForm() {
            this.clearform();
            this.completionAcceptanceDisabled = false;
        },
        closeForm() {
            this.addButtonShow = false;
            this.completionAcceptanceDisabled = true;
            this.$refs.completionAccetanceForm.clearValidate();
        },
        derive() {
            fileDownload('/business/ElecDispatchFlawManage/workTaskInformDownload', {
                method: 'POST',
                data: this.dataForm,
            });
        },
        activateDeriveButton() {
            this.deriveButton = true;
        },
        closeDeriveButton() {
            this.deriveButton = false;
        },
        addProject() {
            let item = {
                units: '',
                username: '',
            };
            this.dataForm.buildList.push(item);
        },
        delTableData(index) {
            this.dataForm.buildList.splice(index, 1);
        },
        clearform() {
            this.dataForm = {
                projectName: '',
                checkTime: '',
                buildUnit: '',
                buildPrinciplUser: '',
                startWorkTime: '',
                comletionTime: '',
                participationUnit: '',
                participationUnitUser: '',
                PrinciplprojectAndParmas: '',
                checkImage: [],
                buildList: [],
                checkUser: '',
                repetitionCheckUser: '',
                checkAssess: '',
                remark: '',
            };
        },
    },
};
</script>
<style lang="less" scoped>
.el-form-item {
    margin: 0px;
}
.table_td strong {
    color: #f56c6c;
    margin-right: 0.4em;
    opacity: 0.9;
}
form.el-form {
    .el-date-editor {
        width: 100%;
    }
}
</style>