<template>
    <div>
        <el-card class="customcard card_table">
            <!-- <div slot="header" class="clearfix">
                <span>{{ '工程量签证单' }}</span>
            </div> -->
            <el-button type="primary" @click="derive()" v-if="deriveButton" class="derive_button">导出</el-button>
            <el-form
                :model="dataForm"
                ref="projectVisaForm"
                :disabled="projectDisabled"
                :rules="dataRule"
                label-position="top"
            >
                <table border width="100%" id="table">
                    <tr>
                        <td colspan="1"><strong>*</strong>工程名称</td>
                        <td colspan="5">
                            <el-form-item prop="projectName">
                                <el-input v-model="dataForm.projectName" placeholder="请输入工程名称"></el-input>
                            </el-form-item>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="1"><strong>*</strong>建设单位</td>
                        <td colspan="2">
                            <el-form-item prop="constructionUnit">
                                <el-input v-model="dataForm.constructionUnit" placeholder="请输入建设单位"></el-input>
                            </el-form-item>
                        </td>
                        <td colspan="1"><strong>*</strong>施工单位</td>
                        <td colspan="2">
                            <el-form-item prop="buildUnit">
                                <el-input v-model="dataForm.buildUnit" placeholder="请输入施工单位"></el-input>
                            </el-form-item>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1"><strong>*</strong>开工时间</td>
                        <td colspan="2">
                            <el-form-item prop="startWorkTime">
                                <el-date-picker
                                    type="datetime"
                                    v-model="dataForm.startWorkTime"
                                    placeholder="请输入开工时间"
                                    value-format="yyyy-MM-dd hh-mm"
                                    format="yyyy-MM-dd hh-mm"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </td>
                        <td colspan="1"><strong>*</strong>竣工时间</td>
                        <td colspan="2">
                            <el-form-item prop="comletionTime">
                                <el-date-picker
                                    type="datetime"
                                    v-model="dataForm.comletionTime"
                                    placeholder="选择日期时间"
                                    value-format="yyyy-MM-dd hh-mm"
                                    format="yyyy-MM-dd hh-mm"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <el-table border :data="dataForm.projectList">
                                <el-table-column type="index" prop="index" label="序号" width="100"> </el-table-column>
                                <el-table-column prop="projectResult" label="工程内容">
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.projectResult"
                                            placeholder="请输入工程内容"
                                        ></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="unit" label="单位">
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.unit"
                                            placeholder="请输入单位"
                                        ></el-input> </template
                                ></el-table-column>
                                <el-table-column prop="number" label="数量">
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.number"
                                            placeholder="请输入数量"
                                        ></el-input> </template
                                ></el-table-column>
                                <el-table-column prop="oneWeight" label="单重">
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.oneWeight"
                                            placeholder="请输入单重"
                                        ></el-input> </template
                                ></el-table-column>
                                <el-table-column prop="remark" label="备注">
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.remark"
                                            placeholder="请输入备注"
                                        ></el-input> </template
                                ></el-table-column>
                                <el-table-column width="50" label="操作">
                                    <template slot-scope="scope">
                                        <div style="text-align: center" v-if="!projectDisabled">
                                            <i class="el-icon-remove" title="" @click="delTableData(scope.$index)"></i>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button style="width: 100%" @click="addProject" v-if="addButtonShow">添加</el-button>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="2">施工单位</th>
                        <th colspan="2">监理单位</th>
                        <th colspan="2">申请部门</th>
                    </tr>
                    <tr>
                        <td colspan="1"><strong>*</strong>意见</td>
                        <td>
                            <el-form-item prop="buildOpinion"
                                ><el-input
                                    type="textarea"
                                    v-model="dataForm.buildOpinion"
                                    placeholder="请输入施工单位意见"
                                ></el-input>
                            </el-form-item>
                        </td>
                        <td colspan="1"><strong>*</strong>意见</td>
                        <td>
                            <el-form-item prop="supervisorOpinion"
                                ><el-input
                                    v-model="dataForm.supervisorOpinion"
                                    type="textarea"
                                    placeholder="请输入监理单位意见"
                                ></el-input>
                            </el-form-item>
                        </td>
                        <td colspan="1"><strong>*</strong>意见</td>
                        <td>
                            <el-form-item prop="applyOpinion"
                                ><el-input
                                    type="textarea"
                                    v-model="dataForm.applyOpinion"
                                    placeholder="请输入申请部门意见"
                                ></el-input>
                            </el-form-item>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1"><strong>*</strong>签字</td>
                        <td>
                            <el-form-item prop="buildSignature"
                                ><el-input v-model="dataForm.buildSignature" placeholder="请输入负责人姓名"></el-input>
                            </el-form-item>
                        </td>
                        <td colspan="1"><strong>*</strong>签字</td>
                        <td>
                            <el-form-item prop="supervisorSignature"
                                ><el-input
                                    v-model="dataForm.supervisorSignature"
                                    placeholder="请输入负责人姓名"
                                ></el-input>
                            </el-form-item>
                        </td>
                        <td colspan="1"><strong>*</strong>签字</td>
                        <td>
                            <el-form-item prop="applySignature"
                                ><el-input v-model="dataForm.applySignature" placeholder="请输入负责人姓名"></el-input>
                            </el-form-item>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1"><strong>*</strong>日期</td>
                        <td>
                            <el-form-item prop="buildSignatureTime">
                                <el-date-picker
                                    type="date"
                                    v-model="dataForm.buildSignatureTime"
                                    placeholder="选择签字确认日期"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    style="width: 100%"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </td>
                        <td colspan="1"><strong>*</strong>日期</td>
                        <td>
                            <el-form-item prop="superviosrSignatureTime">
                                <el-date-picker
                                    type="date"
                                    v-model="dataForm.superviosrSignatureTime"
                                    placeholder="选择签字确认日期"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    style="width: 100%"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </td>
                        <td colspan="1"><strong>*</strong>日期</td>
                        <td>
                            <el-form-item prop="applySignatureTime">
                                <el-date-picker
                                    type="date"
                                    v-model="dataForm.applySignatureTime"
                                    placeholder="选择签字确认日期"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    style="width: 100%"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </td>
                    </tr>
                </table>
            </el-form>
            <div v-if="projectButton">
                <el-row>
                    <el-col class="button">
                        <br />
                        <el-button type="primary" @click="onChange(false)" v-if="!dataForm.projectPass">驳回</el-button>
                        <el-button
                            :type="dataForm.projectPass == true ? 'Warning' : 'primary'"
                            @click="onChange(true)"
                            :disabled="dataForm.projectPass"
                            >{{ dataForm.projectPass == true ? '已同意' : '同意' }}</el-button
                        >
                    </el-col>
                </el-row>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'projectVisaForm',
    components: {},
    data() {
        return {
            projectDisabled: false,
            projectButton: false,
            addButtonShow: true,
            deriveButton: false,
            dataForm: {
                projectName: '',
                constructionUnit: '',
                buildUnit: '',
                projectList: [],
                buildOpinion: '',
                supervisorOpinion: '',
                applyOpinion: '',
                buildSignature: '',
                startWorkTime: '',
                comletionTime: '',
                supervisorSignature: '',
                applySignature: '',
                buildSignatureTime: '',
                superviosrSignatureTime: '',
                applySignatureTime: '',
            },
            dataRule: {
                projectName: [{required: true, message: '请输入', trigger: 'blur'}],
                constructionUnit: [{required: true, message: '请输入', trigger: 'blur'}],
                buildUnit: [{required: true, message: '请输入', trigger: 'blur'}],
                // projectList: [{required: true, message: '请输入', trigger: 'blur'}],
                buildOpinion: [{required: true, message: '请输入', trigger: 'blur'}],
                supervisorOpinion: [{required: true, message: '请输入', trigger: 'blur'}],
                applyOpinion: [{required: true, message: '请输入', trigger: 'blur'}],
                buildSignature: [{required: true, message: '请输入', trigger: 'blur'}],
                supervisorSignature: [{required: true, message: '请输入', trigger: 'blur'}],
                applySignature: [{required: true, message: '请输入', trigger: 'blur'}],
                buildSignatureTime: [{required: true, message: '请输入', trigger: 'blur'}],
                superviosrSignatureTime: [{required: true, message: '请输入', trigger: 'blur'}],
                applySignatureTime: [{required: true, message: '请输入', trigger: 'blur'}],
                startWorkTime: [{required: true, message: '请输入', trigger: 'blur'}],
                comletionTime: [{required: true, message: '请输入', trigger: 'blur'}],
            },
            info: {},
        };
    },
    methods: {
        async init(dataForm) {
            if (dataForm != null) {
                this.dataForm = dataForm;
                this.projectDisabled = true;
            }
            this.addButtonShow = true;
            this.closeDeriveButton();
        },
        addProject() {
            let item = {
                projectResult: '',
                unit: '',
                number: '',
                oneWeight: '',
                remark: '',
            };
            this.dataForm.projectList.push(item);
            console.log(this.dataForm.projectList);
        },
        createForm() {
            return this.dataForm;
        },
        closeForm() {
            this.projectDisabled = true;
            this.addButtonShow = false;
            this.$refs.projectVisaForm.clearValidate();
        },
        activateForm() {
            this.clearform();
            this.projectDisabled = false;
        },
        activatebutton() {
            //激活审核按钮
            this.projectButton = true;
        },
        closeButton() {
            this.projectButton = false;
        },
        onChange(isTrue) {
            if (this.dataForm.projectPass == true) {
                this.$emit('closeCollapse');
            } else {
                this.$refs.projectVisaForm.validate((is) => {
                    if (is) {
                        this.dataForm.projectPass = isTrue;
                        this.$emit('auditForm', isTrue);
                    }
                });
            }
        },
        derive() {
            fileDownload('/business/ElecDispatchFlawManage/workTaskInformDownload', {
                method: 'POST',
                data: this.dataForm,
            });
        },
        activateDeriveButton() {
            this.deriveButton = true;
        },
        closeDeriveButton() {
            this.deriveButton = false;
        },
        delTableData(index) {
            this.dataForm.projectList.splice(index, 1);
        },
        clearform() {
            this.dataForm = {
                projectName: '',
                constructionUnit: '',
                buildUnit: '',
                projectList: [],
                buildOpinion: '',
                supervisorOpinion: '',
                applyOpinion: '',
                buildSignature: '',
                supervisorSignature: '',
                applySignature: '',
                buildSignatureTime: '',
                superviosrSignatureTime: '',
                applySignatureTime: '',
                startWorkTime: '',
                comletionTime: '',
            };
        },
    },
};
</script>
<style lang="less" scoped>
.el-row {
    .button {
        text-align: center;
        padding: 20px 0 0 0;
        // .el-button--primary {
        //     background: #0c0e13;
        // }
    }
}
.el-form-item {
    margin: 0px;
}
strong {
    color: #f56c6c;
    margin-right: 0.4em;
    opacity: 0.9;
}
/deep/.el-button.is-disabled {
    background-color: #0b1020;
    border-color: #0b1020;
}
form.el-form {
    .el-date-editor {
        width: 100%;
    }
}
</style>