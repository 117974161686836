<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100" style="margin: 20px">
        <div style="margin: 0 20px 20px 20px" v-show="listVisible" class="ui-layout-content--full ui-layout-flex--1">
            <el-row>
                <el-button type="primary" v-if="deriveVisible" @click="deriveFlawApplication()">导出隐患记录</el-button>
                <el-button type="primary" @click="handleCommand()" v-if="addFlawApplication">新建隐患申请</el-button>
            </el-row>
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="initList"
                listField="items"
                :columns="columns"
                pageSizeKey="size"
                totalField="totalItemCount"
                pageIndexKey="current"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
            >
                <template slot="append">
                    <el-table-column align="center" label="操作" type="action" width="250">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="show(scope.row)" v-if="showFlawApplication"
                                >查看</el-button
                            >
                            <el-button type="primary" @click="handle(scope.row)" v-if="handlebutton(scope.row)"
                                >处理</el-button
                            >
                            <el-button type="primary" @click="deleteForm(scope.row)" v-if="deletebutton(scope.row)"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
            <el-dialog title="导出" :visible.sync="dialogVisible" width="30%">
                <div>
                    <el-select v-model="isAll" placeholder="请选择">
                        <el-option
                            v-for="item in deriveOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div v-show="!listVisible ? true : false">
            <div style="text-align: center" class="detailTitle">
                <span
                    ><h2>{{ title }}</h2></span
                >
            </div>
            <div style="padding: 30px"></div>
            <div style="padding: 30px">
                <ex-card
                    v-show="FlawApplictionVisible"
                    name="flawApplication"
                    headerName="隐患申请单"
                    style="margin-top: 30px"
                >
                    <flaw-application
                        ref="flawApplication"
                        @refreshDataList="refreshDataList"
                        :facilityVoltageOption="facilityVoltageOption"
                        :questionClassifyOption="questionClassifyOption"
                        :flawLevelOption="flawLevelOption"
                        :notifyUnitsOptions="notifyUnitsOptions"
                        :substationOrLineNameOption="substationOrLineNameOption"
                        :userList="userList"
                    ></flaw-application>
                </ex-card>
                <ex-card v-show="handleVisible" headerName="工作任务通知单" name="handle" style="margin-top: 30px">
                    <handle
                        ref="handle"
                        :facilityVoltageOption="facilityVoltageOption"
                        :notifyUnitsOptions="notifyUnitsOptions"
                        @refreshDataList="refreshDataList"
                    >
                    </handle>
                </ex-card>
                <ex-card v-show="receiptVisible" headerName="缺陷回执单" name="receipt" style="margin-top: 30px">
                    <template slot="title">
                        <h5>{{ '隐患回执单   ' }}</h5>
                        <h6>展开明细</h6>
                    </template>
                    <receipt
                        ref="receipt"
                        :handleResultOption="handleResultOption"
                        :handleClassifyOption="handleClassifyOption"
                        @auditForm="auditForm"
                        @closeCollapse="closeCollapse"
                    ></receipt>
                </ex-card>
                <ex-card
                    v-show="acceptanceReceiveVisible"
                    headerName="工程验收申请资料"
                    name="acceptanceReceive"
                    style="margin-top: 30px"
                >
                    <acceptance-receive ref="acceptanceReceive"> </acceptance-receive>
                </ex-card>
                <ex-card
                    v-show="completionAccetanceVisible"
                    headerName="竣工验收报告"
                    name="completionAccetance"
                    style="margin-top: 30px"
                >
                    <completion-accetance ref="completionAccetance"> </completion-accetance>
                </ex-card>
                <ex-card
                    v-show="projectVisaFormVisible"
                    headerName="工程量签证单"
                    name="projectVisa"
                    style="margin-top: 30px"
                >
                    <project-visa-form
                        ref="projectVisa"
                        @auditForm="auditForm"
                        @closeCollapse="closeCollapse"
                    ></project-visa-form>
                </ex-card>
            </div>

            <!-- </el-collapse> -->
            <div class="submit-btn-group" v-if="buttonVisible">
                <el-button @click="closeCollapse()">关闭</el-button>
                <el-button @click="createRceiptApplication('next')" type="primary" v-if="buttonsubmitVisible">{{
                    buttonText
                }}</el-button>
                <el-button
                    @click="$refs.flawApplication.createApplication()"
                    v-if="flawApplicationVisble"
                    type="primary"
                    >提交</el-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import FlawApplication from './flawApplication';
import Handle from './handle';
import Receipt from './receipt';
import projectVisaForm from './projectVisaForm';
import acceptanceReceive from './acceptanceReceive';
import completionAccetance from './completionAcceptance';
import dayjs from 'dayjs';
const deriveOptions = [
    {
        label: '当前页面',
        value: 0,
    },
    {
        label: '全部',
        value: 1,
    },
];

export default {
    name: 'index',
    data() {
        return {
            FlawApplictionVisible: false,
            flawApplicationVisble: false,
            handleVisible: false, // 工作任务通知单
            receiptVisible: false,
            projectVisaFormVisible: false,
            acceptanceReceiveVisible: false,
            completionAccetanceVisible: false,
            selectedRow: null,
            isAll: '',
            dialogVisible: false,
            deriveOptions,
            notifyUnitsOptions: [],
            listVisible: true,
            facilityVoltageOption: [],
            questionClassifyOption: [],
            flawLevelOption: [],
            substationOrLineNameOption: [],
            facilityNameOption: [],
            handleResultOption: [],
            handleClassifyOption: [],
            businesStatusOption: [],
            userList: [],
            instances: [],
            curInstances: {},
            buttonText: '提交',
            buttonVisible: true,
            buttonsubmitVisible: true,
            businessStatus: '',
            addFlawApplication: false, // 运维新建
            deleteFlawApplication: false,
            deriveVisible: false,
            showFlawApplication: false,
            nextRoles: [],
            Roles: [],
            title: '',
        };
    },
    components: {
        FlawApplication,
        Handle,
        Receipt,
        projectVisaForm,
        acceptanceReceive,
        completionAccetance,
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'data.flawEstate',
                    label: '隐患等级',
                    formatter: function (row) {
                        if (row.data.flawApplication != null && row.data.flawApplication.flawEstate != '') {
                            let obj = this.flawLevelOption.filter(
                                (item) => item.value === row.data.flawApplication.flawEstate
                            );
                            return obj.length > 0 ? obj[0].name : '---';
                        }
                        return '---';
                    }.bind(this),
                },
                {
                    prop: 'data.flawApplication.substationOrLineName[0]',
                    label: '线路/变电站名称',
                    formatter: function (row) {
                        if (row.data.flawApplication != null && row.data.flawApplication.substationOrLineName != null) {
                            // debugger;
                            let indexList = row.data.flawApplication.substationOrLineName;
                            let func = this.handleTreeData(indexList.length + 1, indexList);
                            let data = func(this.substationOrLineNameOption);
                            return data.length > 0 ? data[0].name : '--';
                        }
                        return '---';
                    }.bind(this),
                },
                {
                    prop: 'data.flawApplication.facilityName[0]',
                    label: '设备名称',
                    render: (row) => {
                        return row.data.flawApplication != null && row.data.flawApplication.facilityName != null
                            ? row.data.flawApplication.facilityName
                            : '---';
                    },
                },
                {
                    prop: 'businessStatus',
                    label: '业务状态',
                    render: (row) => {
                        let instance = this.instances.filter(
                            (item) => item.records[item.records.length - 1].id === row.id
                        );
                        if (instance.length > 0) {
                            let obj = this.businesStatusOption.filter((item) => {
                                if (instance[0].nextNode != null) {
                                    return item.value === instance[0].nextNode.code;
                                }
                                return false;
                            });
                            return obj.length > 0 ? obj[0].name : '已处理';
                        }
                        return '---';
                    },
                },
                {
                    prop: 'data.handleRresult',
                    label: '处理结果',
                    formatter: function (row) {
                        if (row.data.receiptForm != null && row.data.receiptForm.handleResult != '') {
                            let instance = this.instances.filter(
                                (item) => item.records[item.records.length - 1].id === row.id
                            );
                            // debugger;
                            if (instance.length > 0 && instance[0].nextNode != null) {
                                let businesStatus = this.businesStatusOption.filter((item) => {
                                    if (instance.length > 0 && instance[0].nextNode != null) {
                                        return item.value === instance[0].nextNode.code;
                                    }
                                    return false;
                                });
                                if (businesStatus[0].code === 'elec_dispatcn_flaw_add_work_issued') {
                                    return '---';
                                }
                            }
                            let handleResultName = this.handleResultOption.filter(
                                (item) => item.value === row.data.receiptForm.handleResult
                            );
                            return handleResultName.length > 0 ? handleResultName[0].name : '---';
                        }
                        return '---';
                    }.bind(this),
                },
                {
                    prop: 'data.flawApplicationForm.discoveryUnits',
                    label: '申请提交单位',
                    render: (row) => {
                        //
                        if (row.data.flawApplication != null) {
                            return row.data.flawApplication.discoveryUnits != null
                                ? row.data.flawApplication.discoveryUnits
                                : '---';
                        }
                    },
                },
                {
                    prop: 'startTime',
                    label: '申请提交时间',
                    formatter: (row) => {
                        if (row) {
                            return dayjs.utc(row.startTime).add(8, 'hours').format('YYYY-MM-DD HH-mm');
                        }
                    },
                },
                {
                    prop: 'data.handleForm.notifyUnits',
                    label: '任务处理单位',
                    formatter: (row, cloums, da, index) => {
                        //
                        if (row.data.handleForm != null && row.data.handleForm.notifyUnits != null) {
                            let obj = this.notifyUnitsOptions.filter((i) => i.id === row.data.handleForm.notifyUnits);
                            return obj.length > 0 ? obj[0].name : '---';
                        }
                        return '---';
                    },
                },
                {
                    prop: 'data.handleForm.limitedPeriod',
                    label: '任务处理期限',
                    formatter: (row, cloums, da, index) => {
                        if (row.data.handleForm != null && row.data.handleForm.limitedPeriod != null) {
                            return row.data.handleForm.limitedPeriod;
                        }
                        return '---';
                    },
                },
                {
                    prop: 'data.receipt.absentEndTime',
                    label: '任务完成时间',
                    formatter: (row, cloums, da, index) => {
                        if (row.data.receiptForm != null && row.data.receiptForm.absentEndTime != null) {
                            let instance = this.instances.filter(
                                (item) => item.records[item.records.length - 1].id === row.id
                            );
                            if (instance.length > 0 && instance[0].nextNode != null) {
                                let businesStatus = this.businesStatusOption.filter((item) => {
                                    if (instance.length > 0 && instance[0].nextNode != null) {
                                        return item.value === instance[0].nextNode.code;
                                    }
                                    return false;
                                });
                                if (businesStatus[0].code === 'elec_dispatcn_flaw_add_work_issued') {
                                    return '---';
                                }
                            }
                            return row.data.receiptForm.absentEndTime;
                        }
                        return '---';
                    },
                },
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'flawLevel',
                        label: '',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请隐患等级',
                        options: this.flawLevelOption,
                        labelKey: 'name',
                        valueKey: 'value',
                    },
                    {
                        prop: 'businessStatus',
                        label: '',
                        itemType: 'select',
                        clearable: true,
                        placeholder: '请选择业务状态',
                        options: this.businesStatusOption,
                        labelKey: 'name',
                        valueKey: 'code',
                    },
                    {
                        prop: 'handleResult',
                        label: '',
                        itemType: 'select',
                        clearable: true,
                        placeholder: '请选择处理结果',
                        options: this.handleResultOption,
                        labelKey: 'name',
                        valueKey: 'value',
                    },
                    {
                        prop: 'notifyUnits',
                        label: '',
                        itemType: 'select',
                        clearable: true,
                        placeholder: '请选择下发处理单位',
                        options: this.notifyUnitsOptions,
                        labelKey: 'name',
                        valueKey: 'id',
                    },
                ],
            };
        },
    },
    mounted() {
        this.$client.getPowerGridsTrees().then((res) => {
            this.substationOrLineNameOption = res.data;
            this.facilityNameOption = res.data;
        });
    },
    created() {
        let nextCode = ['HIDDEN_MANAGE', 'FJB', 'KZYW']; // 5 , 15 , 54
        //运维新建
        this.$store.state.userInfo.roleCodeList.forEach((e) => {
            switch (e) {
                case 'KZYW': // 运维
                    this.showFlawApplication = true;
                    this.addFlawApplication = true;
                    this.deleteFlawApplication = true;
                    this.deriveVisible = true;
                    break;
                case 'HIDDEN_MANAGE': //隐患
                    // this.addFlawApplication = true;
                    this.deleteFlawApplication = true;
                    this.showFlawApplication = true;
                    this.deriveVisible = true;
                    break;
                case 'FJB': //发计部
                    this.showFlawApplication = true;
                    break;
                default:
                    this.showFlawApplication = true;
                    break;
            }
        });
        let codes = [
            'elec_hiddenLevel', //隐患等级
            'elec_questionClassification', //问题分类
            'voltage', //电压等级
            'elec_handleResult', //处理结果
            'elec_handle_classify', //处理措施分类
            'elec_hidden_businesStatus', // 业务状态
        ];
        Promise.all([
            this.$client.listDictionaryChildrenArr(codes),
            this.$client.getOrgUnitByOrgTypeAndCode({orgType: 'department', code: 'WB'}),
            this.$client.getPowerGridsTree(),
            this.$client.getRolesBycodes(nextCode),
            this.$client.getOrgUnitUserListByOrgId({unitId: this.$store.state.userInfo.orgList[0].id}),
            this.$client.workflowInstanceQueryList({DefinitionCode: 'elec_dispatcn_hidden'}),
        ]).then((res) => {
            this.facilityVoltageOption = res[0].data.voltage;
            this.questionClassifyOption = res[0].data.elec_questionClassification;
            this.flawLevelOption = res[0].data.elec_hiddenLevel;
            this.handleResultOption = res[0].data.elec_handleResult;
            this.handleClassifyOption = res[0].data.elec_handle_classify;
            this.businesStatusOption = res[0].data.elec_hidden_businesStatus;
            this.notifyUnitsOptions = res[1].data;
            this.substationOrLineNameOption = res[2].data;
            this.facilityNameOption = res[2].data;
            this.Roles = res[3].data;
            this.userList = res[4].data.records;
            this.instances = res[5].data.items;
        });
    },
    methods: {
        handleCommand() {
            this.flawApplicationVisble = true;
            this.listVisible = false;
            this.buttonVisible = true;
            this.buttonsubmitVisible = false;
            this.FlawApplictionVisible = true;
            this.title = '隐患申报';
            this.$nextTick(() => {
                // this.$refs.flawApplication.activateButton();
                this.$refs.flawApplication.activateForm();
            });
        },
        closeCollapse() {
            this.listVisible = true;
            this.handleVisible = false;
            this.FlawApplictionVisible = false;
            this.receiptVisible = false;
            this.acceptanceReceiveVisible = false;
            this.completionAccetanceVisible = false;
            this.projectVisaFormVisible = false;
            this.closeForm();
        },
        deriveFlawApplication() {
            this.dialogVisible = true;
        },
        /**
         * 刷新列表
         */
        refreshDataList() {
            this.closeCollapse();
            this.$client.workflowInstanceQueryList({DefinitionCode: 'elec_dispatcn_hidden'}).then(async (res) => {
                this.instances = res.data.items;
                this.$refs.searchTable.searchHandler();
            });
        },
        async initList(params) {
            params.DefinitionCode = 'elec_dispatcn_hidden';
            params.flawLevel ? (params['extraProp.flawEstate'] = params.flawLevel) : null;
            params.notifyUnits ? (params['extraProp.notifyUnits'] = params.notifyUnits) : null;
            params.businessStatus ? (params['extraProp.curNodeCode'] = params.businessStatus) : null;
            params.handleResult ? (params['extraProp.handleResult'] = params.handleResult) : null;
             // params.type = 'pending';
            let ids = [];
            let instanceIds = [];
            //获取实例列表
            await this.$client.workflowInstanceQueryList(params).then(async (res) => {
                ids = res.data.items.map((item) => item.records[item.records.length - 1].id);
                instanceIds = res.data.items.map(item => item.id)
            });
            params.ids = ids;
            return new Promise((resolve, reject) => {
                this.$client.getQueryRecords(params).then((res) => {
                    let data = res.data;
                    let i = 0;
                    data.items.forEach((e) => {
                        e.instanceId = instanceIds[i++];
                    });
                    resolve({data: data});
                });
            });
        },
        async handle(data) {
            this.listVisible = false;
            this.buttonsubmitVisible = false;
            let instance = this.instances.filter((item) => item.records[item.records.length - 1].id === data.id);
            this.curInstances = instance;
            let businesStatus = this.businesStatusOption.filter((item) => {
                if (instance.length > 0 && instance[0].nextNode != null) {
                    return item.value === instance[0].nextNode.code;
                }
                return false;
            });
            let code = businesStatus.length > 0 ? businesStatus[0].code : 'elec_dispatcn_hidden_audit';
            let dataForm = {};
            await this.$client.getQueryRecords({ids: [data.id]}).then(async (res) => {
                dataForm = res.data.items.map((item) => item.data);
            });
            //流程对应表单
            this.isWorkForm(code);
            //激活表单
            this.activateAndGiveForm(dataForm);
            switch (code) {
                case 'elec_dispatcn_hidden_add_apply': //待下发
                    this.buttonText = '下发';
                    this.title = '工作任务通知下发';
                    this.buttonVisible = true;
                    if (this.$store.state.userInfo.roleCodeList.includes('HIDDEN_MANAGE')) {
                        this.buttonsubmitVisible = true;
                        // this.$refs.handle.activateDeriveButton(); //激活导出按钮
                        // this.$refs.flawApplication.activateDeriveButton();
                    }
                    if (this.$store.state.userInfo.roleCodeList.includes('KZYW')) {
                        // this.$refs.handle.activateDeriveButton(); //激活导出按钮
                        // this.$refs.flawApplication.activateDeriveButton();
                    }
                    break;
                case 'elec_dispatcn_hidden_add_work_issued': //执行中
                    this.buttonText = '回执';
                    this.title = '隐患回执处理';
                    this.buttonVisible = true;
                    if (this.$store.state.userInfo.roleCodeList.includes('KZYW')) {
                        this.buttonsubmitVisible = true;
                        // this.activateDeriveButton(); // 激活所有导出按钮
                    }
                    if (this.$store.state.userInfo.roleCodeList.includes('HIDDEN_MANAGE')) {
                        // this.activateDeriveButton(); // 激活所有导出按钮
                    }
                    break;
                case 'elec_dispatcn_hidden_add_receipt': //待审核
                    this.buttonVisible = true;
                    this.title = '审核';
                    this.buttonsubmitVisible = false;
                    if (this.$store.state.userInfo.roleCodeList.includes('HIDDEN_MANAGE')) {
                        // this.$refs.projectVisa.activatebutton();
                        this.$refs.receipt.activatebutton(); //激活审核
                        // this.activateDeriveButton(); // 激活所有导出按钮
                    }
                    if (this.$store.state.userInfo.roleCodeList.includes('FJB')) {
                        // this.activateDeriveButton(); // 激活所有导出按钮
                        // this.$refs.projectVisa.activatebutton(); //激活审核
                        this.$refs.receipt.activatebutton(); //激活审核
                    }
                    break;
                case 'elec_dispatcn_hidden_audit': //已处理
                    this.buttonText = '查看';
                    this.title = '隐患已处理';
                    this.buttonVisible = true;
                    this.buttonsubmitVisible = false;
                    this.$refs.receipt.activatebutton(); //激活审核
                    // this.closeForm();
                    break;
                default:
                    this.$message({
                        message: '你没有权限',
                        type: 'error',
                    });
            }
            if (!this.buttonsubmitVisible) this.closeForm();
        },
        async show(data) {
            await this.handle(data);
            this.$nextTick(() => {
                this.closeForm();
                this.closeButton();
                this.listVisible = false;
                this.buttonVisible = true;
                this.buttonsubmitVisible = false;
            });
        },
        activateDeriveButton() {
            //激活所有导出按钮
            this.$refs.handle.activateDeriveButton();
            this.$refs.flawApplication.activateDeriveButton();
            this.$refs.receipt.activateDeriveButton();
            this.$refs.acceptanceReceive.activateDeriveButton();
            this.$refs.completionAccetance.activateDeriveButton();
            this.$refs.projectVisa.activateDeriveButton();
        },
        closeForm() {
            //关闭所有表单，禁用
            this.$refs.flawApplication.closeForm();
            this.$refs.handle.closeForm();
            this.$refs.receipt.closeForm();
            this.$refs.projectVisa.closeForm();
            this.$refs.acceptanceReceive.closeForm();
            this.$refs.completionAccetance.closeForm();
            // this.$refs.handle.$refs.handleForm.resetFields();
            // this.$refs.receipt.$refs.receiptForm.resetFields();
            // this.$refs.acceptanceReceive.$refs.acceptanceReceiveForm.resetFields();
            // this.$refs.completionAccetance.$refs.completionAccetanceForm.resetFields();
            // this.$refs.projectVisa.$refs.projectVisaForm.resetFields();
        },
        closeButton() {
            this.$refs.receipt.closeButton();
            this.$refs.projectVisa.closeButton();
        },
        createRceiptApplication(node) {
            if (this.curInstances[0].curNode.code !== 'elec_dispatcn_hidden_add_receipt') {
                this.$confirm(`确定提交`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.createRceiptApplicationFrom(node);
                });
            } else {
                this.createRceiptApplicationFrom(node);
            }
        },
        createRceiptApplicationFrom(node) {
            let nextRoles = [];
            switch (this.curInstances[0].curNode.code) {
                case 'elec_dispatcn_hidden_add_apply':
                    nextRoles.push(this.Roles.filter((item) => item.roleCode === 'HIDDEN_MANAGE')[0].roleId); //下一个流程交给隐患管理员
                    break;
                case 'elec_dispatcn_hidden_add_work_issued':
                    nextRoles.push(this.Roles.filter((item) => item.roleCode === 'KZYW')[0].roleId); //下一个流程交给运维
                    break;
                case 'elec_dispatcn_hidden_add_receipt':
                    nextRoles.push(this.Roles.filter((item) => item.roleCode === 'FJB')[0].roleId); //下一个流程交给发计部
                    nextRoles.push(this.Roles.filter((item) => item.roleCode === 'HIDDEN_MANAGE')[0].roleId); //下一个流程交给隐患管理员
                    break;
                default:
                    break;
            }
            const dataForm = {};
            let WorkTickForm = null;
            dataForm.flawApplication = this.$refs.flawApplication.createForm(); //隐患申请单
            let isHandleForm, isReceiptForm, isAcceptanceReceiveForm, isCompletionAccetanceForm, isProjectVisaForm;
            // if(this.handleVisible && this.$refs.handle.$refs.HandleForm.validate)
            if (this.handleVisible) {
                //工作任务
                this.$refs.handle.$refs.handleForm.validate((is) => (isHandleForm = is));
                dataForm.handleForm = this.$refs.handle.createForm();
            }
            if (this.receiptVisible) {
                //隐患回执单
                this.$refs.receipt.$refs.receiptForm.validate((is) => (isReceiptForm = is));
                dataForm.receiptForm = this.$refs.receipt.createForm();
                WorkTickForm = this.$refs.receipt.createWorkTickForm();
            }
            // if (this.acceptanceReceiveVisible) {
            //     //工程验收申请资料
            //     this.$refs.acceptanceReceive.$refs.acceptanceReceiveForm.validate(
            //         (is) => (isAcceptanceReceiveForm = is)
            //     );
            //     dataForm.acceptanceReceiveForm = this.$refs.acceptanceReceive.createForm();
            // }
            // if (this.completionAccetanceVisible) {
            //     //竣工验收报告
            //     this.$refs.completionAccetance.$refs.completionAccetanceForm.validate(
            //         (is) => (isCompletionAccetanceForm = is)
            //     );
            //     dataForm.completionAccetanceForm = this.$refs.completionAccetance.createForm();
            // }
            // if (this.projectVisaFormVisible) {
            //     //工程量签证
            //     this.$refs.projectVisa.$refs.projectVisaForm.validate((is) => (isProjectVisaForm = is));
            //     dataForm.projectVisaForm = this.$refs.projectVisa.createForm();
            // }
            if (
                //进行表单验证
                (!this.handleVisible || (this.handleVisible && isHandleForm)) &&
                (!this.receiptVisible || (this.receiptVisible && isReceiptForm))
                // (!this.acceptanceReceiveVisible || (this.acceptanceReceiveVisible && isAcceptanceReceiveForm)) &&
                // (!this.completionAccetanceVisible ||
                //     (this.completionAccetanceVisible && isCompletionAccetanceForm)) &&
                // (!this.projectVisaFormVisible || (this.projectVisaFormVisible && isProjectVisaForm))
            ) {
                console.log('验证完成');
            } else {
                console.log('验证失败'); //验证失败，表单验证清除
                this.$message({
                    message: '请确认表单输入完整',
                    type: 'error',
                });
                return;
            }
            const RceiptApplication = {
                definitionCode: 'elec_dispatcn_hidden',
                nodeCode: '',
                operationType: 'saveAndNext',
                rejectNodeCode: '',
                data: dataForm,
                extraProp: {
                    handleResult: dataForm.receiptForm != null ? dataForm.receiptForm.handleResult : '',
                    notifyUnits: dataForm.handleForm != null ? dataForm.handleForm.notifyUnits : '',
                },
                // nextUsers: [this.$store.state.userInfo.username],
                nextRoles: this.nextRoles,
            };

            RceiptApplication.instanceId = this.curInstances[0].id;
            RceiptApplication.nodeCode = this.curInstances[0].nextNode.code;
            if (node === 'reject') {
                // 驳回
                let isAudit = dataForm.receiptForm.receiptPass; //判断审核是否通过
                if (!isAudit) {
                    RceiptApplication.rejectNodeCode = 'elec_dispatcn_hidden_add_receipt';
                    RceiptApplication.operationType = 'reject';
                    RceiptApplication.nodeCode = this.curInstances[0].curNode.code;
                    RceiptApplication.data.projectVisaForm.projectPass = false;
                    RceiptApplication.data.receiptForm.receiptPass = false;
                    RceiptApplication.data.receiptForm.checkUnitOpinion = '';
                    RceiptApplication.data.receiptForm.checkUnitTime = '';
                    // this.$refs.projectVisa.closeButton(); // 关闭审核按钮
                    // this.$refs.receipt.closeButton();
                }
            } else if (node === 'audit') {
                //同意
                let isAudit = dataForm.receiptForm.receiptPass; //判断审核是否通过
                if (!isAudit) {
                    RceiptApplication.operationType = 'save';
                } else {
                    console.log('通过进行下一步');
                }
            }
            this.$client.workflowInstanceProcess(RceiptApplication).then(async (res2) => {
                if (WorkTickForm != null) {
                    WorkTickForm.formId = this.curInstances[0].id;
                    // this.$client.addWorkTickForms(WorkTickForm).then((res) => {});
                }
                await this.refreshDataList();
            });
        },
        async auditForm(isTrue) {
            if (isTrue) {
                this.createRceiptApplication('audit');
            } else {
                this.createRceiptApplication('reject');
            }
        },
        deleteForm(data) {
            this.$confirm(`确定删除选中的数据`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let ids = [data.instanceId];
                if (this.deletebutton(data)) {
                    this.$client.workflowDelete(ids).then((res) => {
                        if (res.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 2000,
                            });
                        }
                        this.refreshDataList();
                    });
                }
            });
        },
        TreeForech(list, index) {
            if (list != null) {
                let data = [];
                let obj = list.filter((item) => item.id === index);
                if (obj.length > 0) {
                    return obj[0];
                }
                list.forEach((e) => {
                    let i = this.TreeForech(e.children, index);
                    if (i != null && i.length > 0) {
                        list.push(i);
                    }
                });

                return data;
            }
        },
        handlebutton(data) {
            // ;
            let instance = this.instances.filter((item) => item.records[item.records.length - 1].id === data.id);
            let isShow = false;
            if (instance.length > 0) {
                let businesStatus = this.businesStatusOption.filter((item) => {
                    if (instance.length > 0 && instance[0].nextNode != null) {
                        return item.value === instance[0].nextNode.code;
                    }
                    return false;
                });
                //
                // console.log(instance[0].curNode.code);
                let code = businesStatus.length > 0 ? businesStatus[0].code : 'elec_dispatcn_flaw_ok';
                // console.log('businesStatus', code);
                switch (code) {
                    case 'elec_dispatcn_hidden_add_apply': //带下发
                        if (this.$store.state.userInfo.roleCodeList.includes('HIDDEN_MANAGE')) {
                            isShow = true;
                        }
                        break;
                    case 'elec_dispatcn_hidden_add_work_issued': //执行中
                        if (
                            // this.$store.state.userInfo.roleCodeList.includes('HIDDEN_MANAGE') ||
                            this.$store.state.userInfo.roleCodeList.includes('KZYW')
                        ) {
                            isShow = true;
                        }
                        break;
                    case 'elec_dispatcn_hidden_add_receipt': //待审核
                        if (
                            this.$store.state.userInfo.roleCodeList.includes('HIDDEN_MANAGE') ||
                            this.$store.state.userInfo.roleCodeList.includes('FJB')
                        ) {
                            isShow = true;
                        }
                        break;
                    case 'elec_dispatcn_hidden_audit':
                        if (
                            this.$store.state.userInfo.roleCodeList.includes('HIDDEN_MANAGE') ||
                            this.$store.state.userInfo.roleCodeList.includes('FJB')
                        ) {
                            isShow = true;
                        }
                        break;
                    default:
                        break;
                }
            }
            return isShow;
        },
        deletebutton(data) {
            let instance = this.instances.filter((item) => item.records[item.records.length - 1].id === data.id);
            let isShow = false;
            if (instance.length > 0 && this.$store.state.userInfo.roleCodeList != null) {
                let businesStatus = this.businesStatusOption.filter((item) => {
                    if (instance.length > 0 && instance[0].nextNode != null) {
                        return item.value === instance[0].nextNode.code;
                    }
                    return false;
                });
                //
                // console.log(instance[0].curNode.code);
                let code = businesStatus.length > 0 ? businesStatus[0].code : 'elec_dispatcn_hidden_ok';
                // console.log('businesStatus', code);
                switch (code) {
                    case 'elec_dispatcn_hidden_add_apply': //带下发
                        if (
                            this.$store.state.userInfo.roleCodeList.includes('HIDDEN_MANAGE') ||
                            this.$store.state.userInfo.roleCodeList.includes('KZYW')
                        ) {
                            isShow = true;
                        }
                        break;
                    case 'elec_dispatcn_hidden_add_work_issued': //执行中
                        if (this.$store.state.userInfo.roleCodeList.includes('HIDDEN_MANAGE')) {
                            isShow = true;
                        }
                        break;
                    case 'elec_dispatcn_hidden_add_receipt': //待审核
                        break;
                    case 'elec_dispatcn_hidden_audit':
                        // isShow = true;
                        break;
                    default:
                        break;
                }
            }
            return isShow;
        },
        handleTreeData(cout, indexList) {
            let num = 0;
            return function handleData2(list) {
                //对一个list递归cout次
                if (num < cout) {
                    let parent = list.filter((item) => item.id === indexList[num]);
                    num++;
                    // console.log(list,indexList[num],parent);
                    if (parent.length > 0 && parent[0].children != null) {
                        let children = handleData2(parent[0].children);
                        return children;
                    }
                    if (parent.length === 1) return parent;
                    return list;
                }
                return list;
            };
        },
        //流程对应表单
        isWorkForm(code) {
            switch (code) {
                case 'elec_dispatcn_hidden_add_apply': //待下发
                    this.FlawApplictionVisible = true;
                    this.handleVisible = true;
                    break;
                case 'elec_dispatcn_hidden_add_work_issued': //执行中
                    this.handleVisible = true;
                    this.FlawApplictionVisible = true;
                    this.receiptVisible = true;
                    // this.acceptanceReceiveVisible = true;
                    // this.projectVisaFormVisible = true;
                    // this.completionAccetanceVisible = true;
                    break;
                case 'elec_dispatcn_hidden_add_receipt': //待审核
                    this.FlawApplictionVisible = true;
                    this.handleVisible = true;
                    this.receiptVisible = true;
                    // this.acceptanceReceiveVisible = true;
                    // this.projectVisaFormVisible = true;
                    // this.completionAccetanceVisible = true;
                    break;
                case 'elec_dispatcn_hidden_audit': //已处理
                    this.FlawApplictionVisible = true;
                    this.handleVisible = true;
                    this.receiptVisible = true;
                    // this.projectVisaFormVisible = true;
                    // this.acceptanceReceiveVisible = true;
                    // this.completionAccetanceVisible = true;
                    break;
                default:
                    this.FlawApplictionVisible = false;
                    this.handleVisible = false;
                    this.receiptVisible = false;
                    // this.projectVisaFormVisible = false;
                    // this.acceptanceReceiveVisible = false;
                    // this.completionAccetanceVisible = false;
                    break;
            }
        },
        //激活并赋值
        activateAndGiveForm(dataForm) {
            if (this.handleVisible) {
                this.$refs.handle.activateForm();
                this.$refs.handle.init(dataForm[0].handleForm);
            }
            if (this.FlawApplictionVisible) {
                this.$refs.flawApplication.activateForm();
                this.$refs.flawApplication.init(dataForm[0].flawApplication);
            }
            if (this.receiptVisible) {
                this.$refs.receipt.activateForm();
                this.$refs.receipt.init(dataForm[0].receiptForm);
            }
            if (this.acceptanceReceiveVisible) {
                this.$refs.acceptanceReceive.activateForm();
                this.$refs.acceptanceReceive.init(dataForm[0].acceptanceReceiveForm);
            }
            if (this.projectVisaFormVisible) {
                this.$refs.projectVisa.activateForm();
                this.$refs.projectVisa.init(dataForm[0].projectVisaForm);
            }
            if (this.completionAccetanceVisible) {
                this.$refs.completionAccetance.activateForm();
                this.$refs.completionAccetance.init(dataForm[0].completionAccetanceForm);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.card_table .el-input--mini {
    /deep/ .el-input__inner {
        border: none;
        // background: transparent;
        // outline: none;
    }
}
.customcard {
    padding: 0;
}
.detailTitle {
    padding: 10px;
}
.derive_button {
    position: static;
    float: right;
    top: 100px;
}
.el-card .is-always-shadow {
    .el-card__body {
        padding: 0;
        .customcard {
            .el-card__body {
                padding: 20px;
            }
        }
    }
}
form.el-form {
    .ulbox {
        .card {
            margin: 0;
        }
    }
    .el-date-editor {
        width: 100%;
    }
}
.submit-btn-group {
    text-align: center;
}
.el-form-item {
    margin: 0px;
}
.el-textarea__inner,
.el-input_inner {
    font-size: 14px;
    color: #d1d1d1;
}
</style>
