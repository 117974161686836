<template>
    <div>
        <el-card class="card-pub-notice card-ext-center-title customcard">
            <!-- <div slot="header" class="clearfix">
                <span>{{ '工作任务通知单' }}</span>
            </div> -->
            <el-button type="primary" @click="derive()" v-if="deriveButton" class="derive_button">导出</el-button>
            <el-form :model="dataForm" :rules="dataRule" ref="handleForm" :disabled="handleDisible">
                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item :label="'通知单位'" prop="notifyUnits">
                            <el-select
                                v-model="dataForm.notifyUnits"
                                placeholder="请输入通知单位"
                                @change="notifyUnitsChange"
                                clearable
                            >
                                <el-option
                                    v-for="item in notifyUnitsOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item :label="'被通知人'" prop="ofNotifyUser">
                            <el-select v-model="dataForm.ofNotifyUser" placeholder="请输入被通知人">
                                <el-option
                                    v-for="item in ofNotifyUserOtion"
                                    :key="item.userId"
                                    :label="item.realName"
                                    :value="item.userId"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item :label="'工作类型'" prop="workType">
                            <el-select v-model="dataForm.workType" placeholder="请输入工作类型">
                                <el-option
                                    v-for="item in workTypeOption"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item :label="'监理'" prop="supervisor">
                            <el-input v-model="dataForm.supervisor" placeholder="请输入监理"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item :label="'限定完成期限'" prop="limitedPeriod">
                            <br />
                            <el-date-picker
                                v-model="dataForm.limitedPeriod"
                                type="date"
                                placeholder="选择日期"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item :label="'电压等级'" prop="facilityVoltage">
                            <el-select v-model="dataForm.facilityVoltage" placeholder="请输入电压等级">
                                <el-option
                                    v-for="item in facilityVoltageOption"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="24">
                        <el-form-item :label="'工作任务'" prop="workTask">
                            <el-input v-model="dataForm.workTask" placeholder="请输入工作任务" type="textarea">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item :label="'工作内容 '" prop="workContent">
                                <el-input v-model="dataForm.workContent" placeholder="请输入工作任务"> </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row> -->
                <el-row :gutter="24">
                    <el-col :span="24">
                        <el-form-item :label="'备注'" prop="remark">
                            <el-input v-model="dataForm.remark" placeholder="请输入备注" type="textarea"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <br />
    </div>
</template>

<script>
import {previewPdf, fileDownload} from '@/utils';

export default {
    name: 'handle',
    components: {},
    data() {
        return {
            dataRule: {
                notifyUnits: [{required: true, message: '请输入通知单位', trigger: 'blur'}],
                ofNotifyUser: [{required: true, message: '请输入通知人', trigger: 'blur'}],
                workType: [{required: true, message: '请输入工作类型', trigger: 'blur'}],
                limitedPeriod: [{required: true, message: '请输入限制时间', trigger: 'blur'}],
                facilityVoltage: [{required: true, message: '请输入电压等级', trigger: 'blur'}],
                workTask: [{required: true, message: '请输入工作任务', trigger: 'blur'}],
            },
            dataForm: {
                notifyUnits: '',
                ofNotifyUser: '',
                workType: '',
                supervisor: '',
                limitedPeriod: '',
                facilityVoltage: '',
                workTask: '',
                remark: '',
            },
            ofNotifyUserOtion: [],
            workTypeOption: [],
            handleDisible: false,
            instance: {},
            deriveButton: false,
        };
    },
    props: ['facilityVoltageOption', 'notifyUnitsOptions'],
    created() {
        this.$client.listDictionaryChildren('elec_workType').then((res) => {
            this.workTypeOption = res.data;
        });
    },
    methods: {
        async init(dataForm) {
            if (dataForm != null) {
                this.dataForm = dataForm;
                this.notifyUnitsChange(dataForm.notifyUnits);
                this.handleDisible = true;
            }
            this.closeDeriveButton();
        },
        activateForm() {
            this.clearform();
            this.handleDisible = false;
        },
        closeForm() {
            this.handleDisible = true;
            this.$refs.handleForm.clearValidate();
        },
        createForm() {
            return this.dataForm;
        },
        notifyUnitsChange(orgId) {
            //被通知人员,关联通知单位
            this.$client.getUserByOrgId(orgId).then((res) => {
                this.ofNotifyUserOtion = res.data;
            });
        },
        derive() {
            fileDownload('/business/ElecDispatchFlawManage/workTaskInformDownload', {
                method: 'POST',
                data: this.dataForm,
            });
        },
        activateDeriveButton() {
            this.deriveButton = true;
        },
        closeDeriveButton() {
            this.deriveButton = false;
        },
        clearform() {
            this.dataForm = {
                notifyUnits: '',
                ofNotifyUser: '',
                workType: '',
                supervisor: '',
                limitedPeriod: '',
                facilityVoltage: '',
                workTask: '',
                remark: '',
            };
        },
    },
};
</script>
<style lang="less" scoped>
.el-form-item {
    margin: 0px;
}
form.el-form {
    .el-date-editor {
        width: 100%;
    }
}
</style>
