<template>
    <div>
        <el-card class="customcard">
            <!-- <div slot="header" class="clearfix">
                <span>{{ '隐患回执单' }}</span>
            </div> -->
            <div>
                <el-button type="primary" @click="derive()" v-if="deriveButton" class="derive_button">导出</el-button>
                <el-form
                    :model="dataForm"
                    :rules="dataRule"
                    ref="receiptForm"
                    :disabled="receiptDisabled"
                    label-position="top"
                >
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item :label="'处理结果'" prop="handleResult">
                                <el-select v-model="dataForm.handleResult" placeholder="请输入处理结果">
                                    <el-option
                                        v-for="item in handleResultOption"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'处理措施分类'" prop="handleClassify">
                                <el-select v-model="dataForm.handleClassify" placeholder="请输入处理措施分类">
                                    <el-option
                                        v-for="item in handleClassifyOption"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option
                                ></el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'消缺开始时间'" prop="absentStartTime">
                                <el-date-picker
                                    v-model="dataForm.absentStartTime"
                                    type="datetime"
                                    placeholder="请输入消缺开始时间"
                                    value-format="yyyy-MM-dd hh-mm"
                                    format="yyyy-MM-dd hh-mm"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'消缺结束时间'" prop="absentEndTime">
                                <el-date-picker
                                    v-model="dataForm.absentEndTime"
                                    type="datetime"
                                    placeholder="请输入消缺结束时间"
                                    value-format="yyyy-MM-dd hh-mm"
                                    format="yyyy-MM-dd hh-mm"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item :label="'工作票类型'" prop="wotkTickType">
                                <el-select
                                    v-model="dataForm.wotkTickType"
                                    placeholder="请选择工作票类型"
                                    @change="workTickChange"
                                >
                                    <el-option
                                        v-for="item in workTicketClassify"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    >
                                    </el-option
                                ></el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="'关联工作票'" prop="wotkTickCode">
                                <el-select v-model="dataForm.wotkTickCode" placeholder="请输入工作票">
                                    <el-option
                                        v-for="item in wotkTbaleListOption"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                    >
                                    </el-option
                                ></el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item :label="'处理内容'" prop="handleContent">
                                <el-input v-model="dataForm.handleContent" placeholder="请输入处理内容" type="textarea">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item :label="'施工单位'" prop="buildUnit">
                                <el-input v-model="dataForm.buildUnit" placeholder="请输入施工单位"> </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'施工单位、部门负责人'" prop="buildUnitPrincipalUser">
                                <el-input
                                    v-model="dataForm.buildUnitPrincipalUser"
                                    placeholder="请输入施工单位、部门负责人"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'施工单位、部门验收时间'" prop="buildUnitCheckTime">
                                <el-date-picker
                                    v-model="dataForm.buildUnitCheckTime"
                                    type="datetime"
                                    placeholder="请输入施工单位、部门验收时间"
                                    value-format="yyyy-MM-dd hh-mm"
                                    format="yyyy-MM-dd hh-mm"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'施工单位、部门验收意见'" prop="buildUnitCheckOpinion">
                                <el-input
                                    v-model="dataForm.buildUnitCheckOpinion"
                                    placeholder="请输入施工单位、部门验收意见"
                                    type="textarea"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item :label="'监理单位、部门'" prop="supervisorUnit">
                                <el-input v-model="dataForm.supervisorUnit" placeholder="请输入监理单位、部门">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'监理单位、部门负责人'" prop="supervisorUnitPrincipalUser">
                                <el-input
                                    v-model="dataForm.supervisorUnitPrincipalUser"
                                    placeholder="请输入监理单位、部门负责人"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'监理单位、部门验收时间'" prop="suervsorUnitCheckTime">
                                <el-date-picker
                                    type="datetime"
                                    v-model="dataForm.suervsorUnitCheckTime"
                                    placeholder="请输入监理单位、部门验收时间"
                                    value-format="yyyy-MM-dd hh-mm"
                                    format="yyyy-MM-dd hh-mm"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'监理单位、部门验收意见'" prop="suervsorUnitCheckOpinion">
                                <el-input
                                    v-model="dataForm.suervsorUnitCheckOpinion"
                                    placeholder="请输入监理单位、部门验收意见"
                                    type="textarea"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item :label="'运维单位'" prop="maintenanceUnit">
                                <el-input v-model="dataForm.maintenanceUnit" placeholder="请输入运维单位"> </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'运维单位负责人'" prop="maintenanceUnitPrncpalUser">
                                <el-input
                                    v-model="dataForm.maintenanceUnitPrncpalUser"
                                    placeholder="请输入运维单位负责人"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'运维单位验收时间'" prop="maintenanceUnitCheckTime">
                                <el-date-picker
                                    type="datetime"
                                    v-model="dataForm.maintenanceUnitCheckTime"
                                    placeholder="请输入运维单位验收时间"
                                    value-format="yyyy-MM-dd hh-mm"
                                    format="yyyy-MM-dd hh-mm"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="'运维单位意见'" prop="maintenanceUnitCheckOpinion">
                                <el-input
                                    v-model="dataForm.maintenanceUnitCheckOpinion"
                                    placeholder="请输入运维单位意见"
                                    type="textarea"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="备注" prop="remark">
                                <el-input v-model="dataForm.remark" placeholder="请输入备注" type="textarea"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item label="消缺后照片" prop="absentImage">
                                <ex-uploader v-model="dataForm.absentImage"> </ex-uploader> </el-form-item
                        ></el-col>
                        <el-col :span="6">
                            <el-form-item label="报告上传" prop="reportImage">
                                <ex-uploader v-model="dataForm.reportImage"> </ex-uploader>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div v-show="receiptButton">
                    <el-form
                        :model="dataForm"
                        :rules="dataRuletow"
                        ref="receiptFormTow"
                        :disabled="dataForm.receiptPass && receiptButton"
                    >
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="验收单位审核意见" prop="checkUnitOpinion">
                                    <el-input
                                        v-model="dataForm.checkUnitOpinion"
                                        placeholder="请输入验收单位审核意见"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="验收单位审核时间" prop="checkUnitTime">
                                    <el-date-picker
                                        type="datetime"
                                        placeholder="选择日期"
                                        v-model="dataForm.checkUnitTime"
                                        value-format="yyyy-MM-dd hh-mm"
                                        format="yyyy-MM-dd hh-mm"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="button">
                                <br />
                                <el-button type="primary" @click="onChange(false)" v-if="!dataForm.receiptPass"
                                    >驳回</el-button
                                >
                                <el-button
                                    :type="dataForm.receiptPass == true ? 'Warning' : 'primary'"
                                    @click="onChange(true)"
                                    :disabled="dataForm.receiptPass"
                                    >{{ dataForm.receiptPass == true ? '已同意' : '同意' }}</el-button
                                >
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'receipt',
    components: {},
    data() {
        return {
            receiptDisabled: false,
            receiptDisabledTwo: false,
            receiptButton: false,
            deriveButton: false,
            checkForm: false,
            wotkTickCodeinit: true,
            dataForm: {
                handleResult: '',
                handleClassify: '',
                absentStartTime: '',
                absentEndTime: '',
                handleContent: '',
                buildUnit: '',
                buildUnitPrincipalUser: '',
                buildUnitCheckTime: '',
                buildUnitCheckOpinion: '',
                supervisorUnit: '',
                supervisorUnitPrincipalUser: '',
                suervsorUnitCheckTime: '',
                suervsorUnitCheckOpinion: '',
                maintenanceUnit: '',
                maintenanceUnitPrncpalUser: '',
                maintenanceUnitCheckTime: '',
                maintenanceUnitCheckOpinion: '',
                remark: '',
                absentImage: [],
                reportImage: [],
                checkUnitOpinion: '',
                checkUnitTime: '',
                wotkTickCode: '',
                wotkTickType: '',
            },
            dataRule: {
                handleResult: [{required: true, message: '请输入', trigger: 'blur'}],
                handleClassify: [{required: true, message: '请输入', trigger: 'blur'}],
                absentStartTime: [{required: true, message: '请输入', trigger: 'blur'}],
                absentEndTime: [{required: true, message: '请输入', trigger: 'blur'}],
                handleContent: [{required: true, message: '请输入', trigger: 'blur'}],
                wotkTickType: [{required: true, message: '请输入', trigger: 'blur'}],
                wotkTickCode: [{required: true, message: '请输入', trigger: 'blur'}],
            },
            dataRuletow: {
                checkUnitOpinion: [{required: true, message: '请输入', trigger: 'blur'}],
                checkUnitTime: [{required: true, message: '请输入', trigger: 'blur'}],
            },
            info: {},
            projectList: [],
            wotkTbaleListOption: [],
            workTicketClassify: [],
        };
    },
    props: ['handleResultOption', 'handleClassifyOption'],
    created() {
        this.$client.listDictionaryChildren('elec_line_work_ticket').then((res) => {
            this.workTicketClassify = res.data;
        });
    },
    methods: {
        async init(dataForm) {
            this.wotkTickCodeinit = false;
            if (dataForm != null) {
                this.dataForm = dataForm;
                this.wotkTickCodeinit = true;
                this.receiptDisabled = true;
                this.workTickChange(dataForm.wotkTickType);
            }
            this.closeDeriveButton();
        },
        activateForm() {
            this.clearform();
            this.receiptDisabled = false;
        },
        createForm() {
            return this.dataForm;
        },
        closeForm() {
            this.receiptDisabled = true;
            this.$refs.receiptForm.clearValidate();
            if (this.receiptButton) this.$refs.receiptFormTwo.clearValidate();
        },
        activatebutton() {
            this.receiptButton = true;
        },
        closeButton() {
            this.receiptButton = false;
        },
        onChange(isTrue) {
            if (this.dataForm.receiptPass == true) {
                this.$emit('closeCollapse');
            } else {
                this.$refs.receiptFormTow.validate((is) => {
                    this.$confirm(`确定提交`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(async () => {
                        if (is) {
                            this.dataForm.receiptPass = isTrue;
                            this.$emit('auditForm', isTrue);
                            // this.receiptButton = false;
                        }
                    });
                });
            }
        },
        derive() {
            fileDownload('/business/ElecDispatchFlawManage/workTaskInformDownload', {
                method: 'POST',
                data: this.dataForm,
            });
        },
        activateDeriveButton() {
            this.deriveButton = true;
        },
        closeDeriveButton() {
            this.deriveButton = false;
        },
        workTickChange(data) {
            if (!this.wotkTickCodeinit) {
                this.dataForm.wotkTickCode = '';
            }
            let params = {
                definitionCode: data,
            };
            this.$client.workflowInstanceQuery(params).then((res) => {
                const recordIds = [];
                res.data.items.forEach((i) => {
                    i.records.forEach((r) => {
                        recordIds.push(r.id);
                    });
                });
                this.$client.workflowInstanceQueryRecords({ids: recordIds, pageSize: 99999}).then((resp) => {
                    this.wotkTbaleListOption = resp.data.items
                        .filter((e) => e.data != null && e.data.code != null && e.data.code != '')
                        .map((e) => {
                            let data = {
                                name:
                                    e.data.name != null && e.data.name != ''
                                        ? e.data.name + '(' + e.data.code + ')'
                                        : '暂无(未输入)',
                                value: e.id,
                            };
                            return data;
                        });
                });
            });
        },
        createWorkTickForm() {
            let workTickForm = {
                workTickCode: this.dataForm.wotkTickCode,
                workTickType: this.dataForm.wotkTickType,
                formType: 'flaw',
            };
            return workTickForm;
        },
        clearform() {
            this.dataForm = {
                handleResult: '',
                handleClassify: '',
                absentStartTime: '',
                absentEndTime: '',
                handleContent: '',
                buildUnit: '',
                buildUnitPrincipalUser: '',
                buildUnitCheckTime: '',
                buildUnitCheckOpinion: '',
                supervisorUnit: '',
                supervisorUnitPrincipalUser: '',
                suervsorUnitCheckTime: '',
                suervsorUnitCheckOpinion: '',
                maintenanceUnit: '',
                maintenanceUnitPrncpalUser: '',
                maintenanceUnitCheckTime: '',
                maintenanceUnitCheckOpinion: '',
                remark: '',
                absentImage: [],
                reportImage: [],
                checkUnitOpinion: '',
                checkUnitTime: '',
                wotkTickCode: '',
                wotkTickType: '',
            };
        },
    },
};
</script>


<style lang="less" scoped>
// table {
// background: #161d30;
// text-align: center;
// .table_beizhu {
//     width: 25%;
// }
// .datetime_picker {
//     width: 30%;
// }
// .table_td {
//     width: 10%;
// }
// }
.el-row {
    .button {
        text-align: center;
        // .el-button--primary {
        //     background: #0c0e13;
        // }
    }
}
.el-form-item {
    margin: 0px;
}
.el-input__inner {
    width: 100%;
}
/deep/.el-button.is-disabled {
    background-color: #0b1020;
    border-color: #0b1020;
}
form.el-form {
    .el-date-editor {
        width: 100%;
    }
}
</style>

