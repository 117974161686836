<template>
    <div>
        <el-card class="customcard card_table">
            <!-- <div slot="header" class="clearfix">
                <span>{{ '工程验收申请资料' }}</span>
            </div> -->
            <el-button type="primary" @click="derive()" v-if="deriveButton" class="derive_button">导出</el-button>
            <el-form
                :model="dataForm"
                ref="acceptanceReceiveForm"
                :disabled="acceptaceReceiveDisabled"
                :rules="dataRule"
                label-position="top"
            >
                <el-row>
                    <el-form-item label="选择项目类型" prop="projectType">
                        <el-select
                            v-model="dataForm.projectType"
                            placeholder="请输入项目类型"
                            @change="tablesOptionChange"
                        >
                            <el-option v-for="item in tablesOption" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item>
                        <table border="1" width="100%">
                            <!-- <colgroup span="2" align="center"></colgroup>
                                <colgroup align="right" style="color: #0000ff"></colgroup> -->
                            <tr>
                                <td colspan="1"><strong>*</strong>验收工程名称</td>
                                <td colspan="3">
                                    <el-form-item prop="projectName">
                                        <el-input
                                            v-model="dataForm.projectName"
                                            placeholder="请输入工程名称"
                                        ></el-input>
                                    </el-form-item>
                                </td>
                                <td colspan="1"><strong>*</strong>文档编号</td>
                                <td colspan="1">
                                    <el-form-item prop="documentCode">
                                        <el-input
                                            v-model="dataForm.documentCode"
                                            placeholder="请输入文档编号"
                                        ></el-input>
                                    </el-form-item>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1"><strong>*</strong>验收范围</td>
                                <td colspan="3">
                                    <el-form-item prop="checkRange">
                                        <el-input
                                            v-model="dataForm.checkRange"
                                            placeholder="请输入验收范围"
                                            type="textarea"
                                        ></el-input>
                                    </el-form-item>
                                </td>
                                <td colspan="1">设备型号</td>
                                <td colspan="1">
                                    <el-form-item prop="unitType">
                                        <el-input
                                            v-model="dataForm.unitType"
                                            placeholder="请输入设备型号"
                                            type="textarea"
                                        ></el-input
                                    ></el-form-item>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>*</strong>验收人员</td>
                                <td colspan="3">
                                    <el-form-item prop="checkUser">
                                        <el-input
                                            v-model="dataForm.checkUser"
                                            placeholder="请输入验收人员"
                                            type="textarea"
                                        ></el-input>
                                    </el-form-item>
                                </td>
                                <td><strong>*</strong>验收时间</td>
                                <td>
                                    <el-form-item prop="checkTime">
                                        <el-date-picker
                                            v-model="dataForm.checkTime"
                                            type="datetime"
                                            placeholder="选择日期时间"
                                            value-format="yyyy-MM-dd hh-mm"
                                            format="yyyy-MM-dd hh-mm"
                                        >
                                        </el-date-picker>
                                    </el-form-item>
                                </td>
                            </tr>
                        </table>
                    </el-form-item>
                    <br />
                    <el-form-item>
                        <table border="1" width="100%" ref="checkItemAndStandard">
                            <tr>
                                <th>序号</th>
                                <th>验收过程中的危险点</th>
                                <th>防范措施</th>
                                <th>备注</th>
                            </tr>
                            <tr v-for="(item, index) in table.dangerous" :key="index">
                                <td colspan="1">{{ item.index }}</td>
                                <td colspan="1">{{ item.data ? item.data[0] : '' }}</td>
                                <td colspan="1">{{ item.data ? item.data[1] : '' }}</td>
                                <td colspan="1">
                                    <el-input v-model="dataForm.dangerous[index]" placeholder="请填写内容"></el-input>
                                </td>
                            </tr>
                            <!-- <tr v-for="item in dangerousOption" :key="item.name"></tr> -->
                        </table>
                    </el-form-item>
                    <el-form-item>
                        <table border="1" width="100%">
                            <th>序号</th>
                            <th>验收项目及标准</th>
                            <th><strong>*</strong>验收记录</th>
                            <th>依据</th>
                            <th class="table_beizhu">备注</th>
                            <tr v-for="(item, index) in table.checkItemList" :key="index">
                                <td colspan="1">{{ item.index ? item.index : '' }}</td>
                                <td colspan="1">{{ item.name ? item.name : '' }}</td>
                                <td colspan="1" style="text-align: center">
                                    <el-checkbox
                                        placeholder=""
                                        v-model="dataForm.checkItemAndStandard[item.index].isCheck"
                                    ></el-checkbox>
                                </td>
                                <td colspan="1">{{ item.according ? item.according : '' }}</td>
                                <td colspan="1">
                                    <el-input
                                        v-model="dataForm.checkItemAndStandard[item.index].remark"
                                        placeholder=""
                                    ></el-input>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1">施工单位</td>
                                <td colspan="3">
                                    <el-form-item prop="buildUnit">
                                        <el-input v-model="dataForm.buildUnit" placeholder="请输入施工单位"></el-input>
                                    </el-form-item>
                                </td>
                                <td colspan="1">
                                    <el-date-picker
                                        v-model="dataForm.buildTime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                        value-format="yyyy-MM-dd hh-mm"
                                        format="yyyy-MM-dd hh-mm"
                                    >
                                    </el-date-picker>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1">监理单位</td>
                                <td colspan="3">
                                    <el-input v-model="dataForm.supervisorUnit" placeholder="请输入监理单位"></el-input>
                                </td>
                                <td colspan="1">
                                    <el-date-picker
                                        type="datetime"
                                        v-model="dataForm.supervisorTime"
                                        placeholder="选择日期时间"
                                        value-format="yyyy-MM-dd hh-mm"
                                        format="yyyy-MM-dd hh-mm"
                                    >
                                    </el-date-picker>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1">申请部门</td>
                                <td colspan="3">
                                    <el-input v-model="dataForm.applyUnit" placeholder="请输入申请部门"></el-input>
                                </td>
                                <td class="datetime_picker" colspan="1">
                                    <el-date-picker
                                        v-model="dataForm.applyTime"
                                        type="datetime"
                                        placeholder="选择日期时间"
                                        value-format="yyyy-MM-dd hh-mm"
                                        format="yyyy-MM-dd hh-mm"
                                    >
                                    </el-date-picker>
                                </td>
                            </tr>
                        </table>
                    </el-form-item>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import TableJson from './AcceptanceReceiveTable.json';

export default {
    name: 'acceptanceReceive',
    components: {},
    data() {
        return {
            dataRule: {
                projectType: [{required: true, message: '请输入', trigger: 'blur'}],
                projectName: [{required: true, message: '请输入', trigger: 'blur'}],
                documentCode: [{required: true, message: '请输入', trigger: 'blur'}],
                checkRange: [{required: true, message: '请输入', trigger: 'blur'}],
                unitType: [{required: true, message: '请输入', trigger: 'blur'}],
                runCode: [{required: true, message: '请输入', trigger: 'blur'}],
                checkUser: [{required: true, message: '请输入', trigger: 'blur'}],
                checkTime: [{required: true, message: '请输入', trigger: 'blur'}],
            },
            dataForm: {
                projectType: '',
                projectName: '',
                documentCode: '',
                checkRange: '',
                unitType: '',
                runCode: '',
                checkUser: '',
                checkTime: '',
                buildUnit: '',
                supervisorUnit: '',
                applyUnit: '',
                buildTime: '',
                supervisorTime: '',
                applyTime: '',
                dangerous: {},
                checkItemAndStandard: {},
            },
            info: {},
            tablesOption: [],
            table: {
                checkItemList: [],
            },
            TableJson: {},
            deriveButton: false,
            acceptaceReceiveDisabled: false,
        };
    },
    created() {
        this.tablesOption = TableJson;
        TableJson.forEach((e) => {
            e.checkItemAndStandard.map((e_2) => {
                if (e_2.data.length > 1) {
                    return;
                }
            });
        });
    },
    methods: {
        async init(dataForm) {
            if (dataForm != null) {
                this.dataForm = dataForm;
                this.table = this.tablesOption.filter((item) => item.id === dataForm.projectType)[0];
                this.$nextTick(() => {
                    this.table.dangerous.forEach((ele, index) => {
                        this.$set(this.dataForm.dangerous, index, dataForm.dangerous[index]);
                    });
                    const checkItemAndStandardData = [];
                    for (let i = 0; i < this.table.checkItemAndStandard.length; i++) {
                        let e = this.table.checkItemAndStandard[i];
                        if (e.data != null)
                            e.data.forEach((e_2, index) => {
                                let obj = {};
                                obj.name = e_2;
                                obj.index = i + 1 + '_' + index;
                                obj.according = e.according;
                                checkItemAndStandardData.push(obj);
                                this.$set(
                                    this.dataForm.checkItemAndStandard,
                                    obj.index,
                                    dataForm.checkItemAndStandard[obj.index]
                                );
                            });
                    }
                    this.table.checkItemList = checkItemAndStandardData;
                });
            } else {
                this.table.checkItemList = [];
                this.table.dangerous = [];
            }
            this.acceptaceReceiveDisabled = true;
            this.closeDeriveButton();
        },
        createForm() {
            return this.dataForm;
        },
        activateForm() {
            this.clearform();
            this.acceptaceReceiveDisabled = false;
        },
        closeForm() {
            this.acceptaceReceiveDisabled = true;
            this.$refs.acceptanceReceiveForm.clearValidate();
        },
        tablesOptionChange(data) {
            this.table = this.tablesOption.filter((item) => item.id === data)[0];
            // this.table.dangerous.forEach((ele, index) => {
            //     this.$set(this.dataForm.dangerous, index, '');
            // });
            const checkItemAndStandardData = [];
            this.table.checkItemAndStandard.forEach((e, i) => {
                e.data.forEach((e_2, index) => {
                    let obj = {};
                    obj.name = e_2;
                    obj.index = i + 1 + '_' + index;
                    obj.according = e.according;
                    checkItemAndStandardData.push(obj);
                    let checkItemAndStandard = {
                        isCheck: true,
                        remark: '',
                    };
                    this.$set(this.dataForm.checkItemAndStandard, obj.index, checkItemAndStandard);
                });
            });
            this.table.checkItemList = checkItemAndStandardData;
        },
        derive() {
            fileDownload('/business/ElecDispatchFlawManage/workTaskInformDownload', {
                method: 'POST',
                data: this.dataForm,
            });
        },
        activateDeriveButton() {
            this.deriveButton = true;
        },
        closeDeriveButton() {
            this.deriveButton = false;
        },
        clearform() {
            this.dataForm = {
                projectType: '',
                projectName: '',
                documentCode: '',
                checkRange: '',
                unitType: '',
                runCode: '',
                checkUser: '',
                checkTime: '',
                buildUnit: '',
                supervisorUnit: '',
                applyUnit: '',
                buildTime: '',
                supervisorTime: '',
                applyTime: '',
                dangerous: {},
                checkItemAndStandard: {},
            };
        },
    },
};
</script>
<style lang="less" scoped>
.el-form-item {
    margin: 0px;
}
strong {
    color: #f56c6c;
    margin-right: 0.4em;
    opacity: 0.9;
}
/deep/.is-checked {
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        background-color: #235eff;
        border-color: #0b1020;
    }
}
form.el-form {
    .el-date-editor {
        width: 100%;
    }
}
</style>